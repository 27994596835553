<template>
    <div ref="fileviewer" class="fileviewer">
        <div style="margin-top:20px" v-if="document.text">
        <b-row style="width:100%;margin-left:100px;padding-top:20px;" class="mb-2 font-weight-bold">
            <b-col md="6">Titre du document : {{ document.text }}</b-col>
            <b-col md="4">Créé le : {{ document.created }}</b-col>
        </b-row>
        <b-row  style="width:100%;margin-left:100px;" class="mb-2 font-weight-bold">
            <b-col md=6>Enregistré par  : {{ document.author }}</b-col>
            <b-col md=6>Version : {{ document.version }}</b-col>
        </b-row>
        </div>
        <hr/>
        <b-row>
            <b-col cols="12">   
        <div v-if="!document.text" style="text-align:center;margin-top:100px;">
            Veuillez sélectionner un document à afficher
        </div>     
        <div v-if="document.type === 'doc' || document.type === 'docx'" class="embed-responsive embed-responsive-21by9">
            <iframe class="embed-responsive-item" v-bind:src="document.path" frameborder="0"></iframe>
        </div>
        <div v-if="document.type === 'pdf'" class="embed-responsive overflow-auto">
            <!--<pdf
                v-for="i in numPages"
                :key="i"
                :src="src2"
                :page="i"
                style="display: inline-block; width: 100%"
            ></pdf>
            <iframe class="embed-responsive-item" v-bind:src="src" frameborder="0"></iframe>-->
            <pdf 
                :src="document.path"
                @num-pages="pageCount = $event"
			    @page-loaded="currentPage = $event"
            ></pdf>
{{currentPage}} / {{pageCount}}
        </div>   

 
        <div v-if="document.type === 'jpg' || document.type === 'jpeg'" class="embed-responsive">
            <img v-bind:src="document.path" class="img-viewer" style="margin:auto"/>
        </div>  
        <div v-if="document.type === 'cad'" class="embed-responsive">
            <IFRAME src="https://autodesk5.autodesk360.com/shares/public/SHabee1QT1a327cf2b7a7c20fdf67c3f5618?mode=embed" width="640" height="480" allowfullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true" frameborder="0"></IFRAME>
        </div>
            </b-col>
        </b-row>    

    </div>
</template>

<script>
import pdf from 'vue-pdf'
import {
 BCol, BRow
} from 'bootstrap-vue'
export default {
  name: "fileviewer",
    props: {
        document: {
            type: Object,
            default: () => ''        
        },                               
    },    
    components: {
        pdf,BCol, BRow,
    },
    data() {
        return {
            currentPage: 0,
			pageCount: 0,
            src:'',
            filetype:'',
        }    
    },

  methods : {

    showjpg: function (src) { 
      console.log('showjpg')
      this.filetype = 'jpg'
      this.src =  src
    },
    showcad: function (src) { 
      this.filetype = 'cad'
    },   
    showtif: function (src) { 
      console.log('showtifd')
      this.filetype = 'tif'
      let myUrl = encodeURIComponent(src);
      
     // chrome-extension://hinllambflhajfhffnaeplcaonpbpkgo/main.html?path=http://www.blackice.com/tiffocx/test.tif
      //this.src='https://docs.google.com/viewer?url=' + myUrl + '&embedded=true';      
      this.src='chrome-extension://hinllambflhajfhffnaeplcaonpbpkgo/main.html?path=' + myUrl;  
    },      
     showpdf: function (src) { 
      console.log('showpdf')
      this.filetype = 'pdf'
      this.src =  src
      //let myUrl = encodeURIComponent(src);
      //this.src='https://docs.google.com/viewer?url=' + myUrl + '&embedded=true';

    // this.src2 =  pdf.createLoadingTask(src); 

      /*this.src2.promise.then(pdf => {

        this.numPages = pdf.numPages;
      });*/
    },  
     showdoc: function (src) { 
      console.log('showdoc')
      this.filetype = 'doc'
     // this.src =  "https://docs.google.com/gview?url=http://ieee802.org/secmail/docIZSEwEqHFr.doc&embedded=true"
      this.src =  "https://view.officeapps.live.com/op/embed.aspx?src="+src
    }, 

  },    
    
}

</script>

<style >
.embed-responsive {
   min-height: 800px;
   overflow: auto;
   text-align:center;
}
.img-viewer {

    max-width:600px;
}
.viewer {
    background-color: #fff;
}

</style>