export default 
    {
    // Table Handlers
    itemsComposant : [
        { type:"Chaussée de la voie portée",nature: '',iqoa:"1",fiche: '1',releve:"releve", sortable: true },
        { type:"Trottoirs, bordures et accotements",nature: '',iqoa:"1",fiche: '',releve:"releve", sortable: true },
        { type:"Dispositifs de retenue",nature: '',iqoa:"1",fiche: '2',releve:"releve", sortable: true },
        { type:"Corniches",nature: '',iqoa:"2",fiche: '',releve:"releve", sortable: true },
        { type:"Dispositifs d'évacuation des eaux",nature: '',iqoa:"3U",fiche: '',releve:"releve", sortable: true },
        { type:"Autres équipements sur ouvrage",nature: '',iqoa:"1",fiche: '',releve:"releve", sortable: true },    
      ],        
  
     itemsComposant1 : [
        { type:"Chaussée de la voie franchie",nature: '',iqoa:"1",fiche: '',releve:"releve", sortable: true },
        { type:"Trottoirs et bordures",nature: '',iqoa:"1",fiche: '',releve:"releve", sortable: true },
        { type:"Dispositifs de retenue",nature: '',iqoa:"1",fiche: '',releve:"releve", sortable: true },
        { type:"Dispositifs d'évacuation des eaux",nature: '',iqoa:"2",fiche: '',releve:"releve", sortable: true },
     ],
  
     itemsComposant2 : [
        { type:"Etanchéité",nature: '',iqoa:"1",fiche: '',releve:"releve", sortable: true },
        { type:"Perrés",nature: '',iqoa:"1",fiche: '',releve:"releve", sortable: true },
        { type:"Eléments de protection en site aquatique",nature: '',iqoa:"1",fiche: '',releve:"releve", sortable: true },
        { type:"Dispositifs latéraux d'évacuation des eaux",nature: '',iqoa:"2",fiche: '',releve:"releve", sortable: true },
     ],
  
     itemsComposant3 : [
        { type:"Tympans et murs en retour",nature: '',iqoa:"1",fiche: '',releve:"releve", sortable: true },
        { type:"Tympans et murs en aile",nature: '',iqoa:"1",fiche: '',releve:"releve", sortable: true },
        { type:"Voûtes et piédroits des culées ou piles",nature: '',iqoa:"1",fiche: '',releve:"releve", sortable: true },
        { type:"Fondations Lit du cours d'eau",nature: '',iqoa:"2",fiche: '',releve:"releve", sortable: true },
        { type:"Eléments de renforcement ou de réparation antérieurs",nature: '',iqoa:"2",fiche: '',releve:"releve", sortable: true },      
     ],
     itemsComposant4 : [
      { type:"Chaussée de la voie portée",nature: '',iqoa:"1",fiche: '1',releve:"releve", sortable: true },
      { type:"Trottoirs, bordures et accotements",nature: '',iqoa:"2",fiche: '',releve:"releve", sortable: true },
      { type:"Dispositifs de retenue",nature: '',iqoa:"1",fiche: '2',releve:"releve", sortable: true },
      { type:"Corniches",nature: '',iqoa:"2E",fiche: '',releve:"releve", sortable: true },
      { type:"Dispositifs d'évacuation des eaux",nature: '',iqoa:"3U",fiche: '',releve:"releve", sortable: true },
      { type:"Autres équipements sur ouvrage",nature: '',iqoa:"1",fiche: '',releve:"releve", sortable: true },    
    ],        
}
  
  