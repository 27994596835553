<template>
  <b-sidebar
    id="add-new-contrainte-sidebar"
    :visible="isAddNewContrainteSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => {$emit('update:is-add-new-contrainte-sidebar-active', val);showContrainte(val)}"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Ajout d'une nouvelle contrainte
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="contrainte"
            rules="required"
          >
            <b-form-group
              label="Contrainte"
              label-for="contrainte"
            >
              <b-form-input
                id="contrainte"
                v-model="contrainteData.contrainte"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />        
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Username -->
          <validation-provider
            #default="validationContext"
            name="tiers"
          >
            <b-form-group
              label="Tiers"
              label-for="tiers"
            >
              <b-form-input
                id="tiers"
                v-model="contrainteData.tiers"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Username -->
          <validation-provider
            #default="validationContext"
            name="delai"
          >
            <b-form-group
              label="Délai"
              label-for="delai"
            >
              <b-form-input
                id="delai"
                v-model="contrainteData.delai"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Username -->
          <validation-provider
            #default="validationContext"
            name="type"
            rules="required"
          >
            <b-form-group
              label="Type d'inspection"
              label-for="type"
            >
              <b-form-input
                id="type"
                v-model="contrainteData.type"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button v-if="!contrainteData.id"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Ajouter
            </b-button>
            <b-button v-if="contrainteData.id"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Mettre à jour
            </b-button>            
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Annuler
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'


export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewContrainteSidebarActive',
    prop: 'composantsList',
    event: 'update:is-add-new-contrainte-sidebar-active',
  },
  props: {
    isAddNewContrainteSidebarActive: {
      type: Boolean,
      required: true,
    },
    inContrainteData: {
      type: Object,
      default: (() => {id:''}),
    },   
    composantsList: {
      type: Array,
      default: []
    }, 
  },

 methods: {
    loadContrainte() {
      if (this.inContrainteData.id != '') {
        this.contrainteData = JSON.parse(JSON.stringify(this.inContrainteData))
      } else {
        this.contrainteData = JSON.parse(JSON.stringify(this.blankContrainteData))
      }
    },
    showContrainte: function (val) { 

      if (val) {
        this.loadContrainte()
      } else {
        this.contrainteData = JSON.parse(JSON.stringify(this.blankContrainteData))
      }
    },
 },  
 
  data() {

    this.loadContrainte()
 
    return {
      required,
      alphaNum,
      email,
      types:['Fissure','Altération','type 1','type 2'],
      iqoas:['1','2','2E','3','3U'],
    }
  },

created () {
    this.blankContrainteData = {
      id: '',
      contrainte: '',
      tiers: '',
      delai:'',
      type:''
    }


},

  setup(props, { emit }) {
    const blankContrainteData = {
      id: '',
      contrainte: '',
      tiers: '',
      delai:'',
      type:''
    }

    let contrainteData = ref(JSON.parse(JSON.stringify(blankContrainteData)))

    if (props.inContrainteData.id != '') {
      contrainteData.value = JSON.parse(JSON.stringify(blankContrainteData))
    }

    const resetcontrainteData = () => {
      contrainteData.value = JSON.parse(JSON.stringify(blankContrainteData))
    }

    const onSubmit = () => {
      emit('update:is-add-new-contrainte-sidebar-active', false)
      emit('addcontrainte', contrainteData.value)
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetcontrainteData)

    return {
      contrainteData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-contrainte-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
