<template>
    <div ref="chartedit">
        <div style="width: 95px; margin-left: auto;" v-if="addbutton"><b-button class="add-button" variant="success" @click="addRowwHandler">+ ligne</b-button></div>
        <b-table class="b-table" :items="tableItems" :fields="fields" fixed>
            <template v-for="(field, index) in fields" #[`cell(${field.key})`]="data">
                <b-form-datepicker v-if="field.type === 'date' && tableItems[data.index].isEdit" :key="index" :type="field.type" :value="tableItems[data.index][field.key]" @input="(value) => inputHandler(value, data.index, field.key)"></b-form-datepicker>
                <b-form-checkbox v-else-if="field.type === 'checkbox'" :key="index" :type="field.type" v-bind:checked="tableItems[data.index].isChecked" v-on:change="$emit('toggle-box',tableItems[data.index])"></b-form-checkbox>
                <b-img v-else-if="field.type === 'image'" :key="index" :src="tableItems[data.index][field.key]" :alt="`${tableItems[data.index].name}`" fluid class="card-img-top" style="width:150px;height:100px;" v-on:click="$emit('addouvrage', tableItems[data.index])"></b-img>
                <b-form-select v-else-if="field.type === 'select' && tableItems[data.index].isEdit" :key="index" :value="tableItems[data.index][field.key]" @input="(value) => inputHandler(value, data.index, field.key)" :options="field.options"></b-form-select>
                <div :key="index" v-else-if="field.type === 'edit'">
                    <b-button @click="editRowHandler(data)">
                        <span v-if="!tableItems[data.index].isEdit">Edit</span>
                        <span v-else>OK</span>
                    </b-button>
                    <b-button class="delete-button" variant="danger" @click="removeRowHandler(data.index)">X</b-button>
                </div>
                <div :key="index" v-else-if="field.type === 'delete'">
                    <b-button class="delete-button" variant="danger" @click="removeRowHandler(data.index)">X</b-button>
                </div>                
                <b-form-input v-else-if="field.type && tableItems[data.index].isEdit" :key="index" :type="field.type" :value="tableItems[data.index][field.key]" @input="(value) => inputHandler(value, data.index, field.key)"></b-form-input>
                <span :key="index" v-else>{{data.value}}</span>
            </template>
        </b-table>
    </div>
</template>

<script>

import {
  BCol, BRow, BFormGroup, BFormInput,BTable,BButton ,BFormSelect, BFormDatepicker,BImg, BFormCheckbox
} from 'bootstrap-vue'

export default {
  name: "chartedit",
  components: {BTable,BButton,BFormInput,BFormSelect,BFormDatepicker,BImg, BFormCheckbox},
  props: {
    value: {
        type: Array,
        default: () => [
        ]        
    },   
    fields: {
        type: Array,
        default: () => [
        ]
    },
    editable: {        
      type: Boolean,
        default: () => true
    },
    addbutton: {        
      type: Boolean,
        default: () => true
    }     
  },
  data() {
    return {
      tableItems: this.value.map(item => ({...item, isEdit: this.editable})),
      checkedProducts:[]
    }
  },
  methods: {
      editRowHandler(data) {
        this.tableItems[data.index].isEdit = !this.tableItems[data.index].isEdit;
      },
      inputHandler(value, index, key) {
        this.tableItems[index][key] = value;
        this.$emit('input', this.tableItems);
      },
      addRowwHandler() {
        const newRow = this.fields.reduce((a, c) => ({...a, [c.key]: null}) ,{})
        newRow.isEdit = true;
        this.tableItems.unshift(newRow);
        this.$emit('input', this.tableItems);
      },
      removeRowHandler(index) {
        this.tableItems = this.tableItems.filter((item, i) => i !== index);
        this.$emit('input', this.tableItems);
      },
  	toggle (ouvrage) {
      ouvrage.isChecked = !ouvrage.isChecked
    },
     
 
    }
};
</script>

<style>
    .add-button {
        margin-bottom: 10px;
    }
    .delete-button {
        margin-left: 5px;
    }
</style>

