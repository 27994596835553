<template>
        <b-modal
        id="modal-releve"
        ref="relevereadmodal"
        @show="beforeShown"
        modal-class="ouvrage-modal nopad"
        cancel-variant="outline-secondary"
        cancel-title="Fermer"
        centered
        title="Relevé graphique associé au composant"
        >
        <div ref="map-root" id="map" style="width:100%;height:500px;">
            <img :src="require('@/assets/images/pages/patrimoine/releve.png')" width="850px" height="600px"/>
        </div>
     

        </b-modal>
</template>

<script>

import {
BButton, BModal, VBModal, BForm, BFormInput, BFormGroup, BCardText,BLink,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import DesordreListAddNew from '../views/apps/desordre/DesordreListAddNew.vue'
import View from 'ol/View'
import Map from 'ol/Map'
import Projection from 'ol/proj/Projection';
import ImageLayer from 'ol/layer/Image';
import Feature from 'ol/Feature';
import {fromLonLat} from 'ol/proj';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCoffee, faUndo, faArrowPointer,faPen, faCircle, faDrawPolygon, faMinus, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
//import {  } from '@fortawesome/free-regular-svg-icons'
import Static from 'ol/source/ImageStatic';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {getCenter} from 'ol/extent';
import Point from 'ol/geom/Point';

import Draw from 'ol/interaction/Draw';
import {OSM, Vector as VectorSource} from 'ol/source';
import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer';
import {defaults as defaultInteractions} from 'ol/interaction'

import {Style, Icon, Text, Fill, Stroke} from 'ol/style';

import { ref, onUnmounted } from '@vue/composition-api'
//import inspectionStoreModule from '../inspection/inspectionStoreModule'

  // importing the OpenLayers stylesheet is required for having
  // good looking buttons!
  import 'ol/ol.css'

export default {
  directives: {
    Ripple,
  },    
  name: "relevereadmodal",
  components: {BButton, BModal, VBModal, BForm, BFormInput, BFormGroup, BCardText,DesordreListAddNew,BLink},
  methods: {
 
    hideModal (id) {
        this.$root.$emit('bv::hide::modal',id)
    },
   
  },

    methods: {

        addAsset: function(asset) {
          let feat = new Feature({name:asset.name,
            geometry: new Point(asset.center),
            center:asset.center,
            assetid:asset.id
          })
          //if (asset.logo) {
            feat.setStyle(new Style({
              image: new Icon({
                      src: asset.icon,
                      scale: asset.scale
              }),
              text: new Text({
                      text: asset.name,
                      offsetY: 20,
                      scale: 1.2,
                      fill: new Fill({
                        color: "#fff"
                      }),
                      stroke: new Stroke({
                        color: "0",
                        width: 3
                      })  
              })              
            }));
          //}
        this.features.push(feat)
        },   
        
        beforeShown: function() {
            const extent = [0, 0, 1024, 500];
            const projection = new Projection({
                code: 'toto-image',
                units: 'pixels',
                extent: extent,
            });

            const myImage = new ImageLayer({
                    source: new Static({
                        //url: 'https://assetmgmt.dev.platform.topoeverywhere.com/img/vue1.7090a670.png',
                        url:require('@/assets/images/pages/patrimoine/releve.png'),
                        projection: projection,
                        imageExtent: extent,
                    }),
                    })

            // this is where we create the OpenLayers map
            const map = new Map({
                // the map will be created using the 'map-root' ref
                target: this.$refs['map-root'],
                layers: [myImage],
                interactions: defaultInteractions({
                doubleClickZoom: false,
                }),        
                view: new View({
                    projection: projection,
                    center: getCenter(extent),
                    zoom: 2,
                    maxZoom: 8,
                }),
            })
            


            this.lstDesordre.forEach(asset => this.addAsset(asset))

            let vectorSource = new VectorSource({
                features: this.features,
                wrapX: false
            });

            let vectorLayer = new VectorLayer({
                source: vectorSource,
                name:'myLayer'
            });

            map.addLayer(vectorLayer)
            
            map.on('dblclick', (evt) => {
                var feature = map.forEachFeatureAtPixel(evt.pixel, function(feature, layer) {
                    //you can add a condition on layer to restrict the listener
                    return feature;
                    });
                if (feature) {
                    if (feature.getGeometry().getType() === "Point") {
                    if (feature.get('assetid'))
                    this.onDesordreEdit(this.desordreData[feature.get('assetid')])
                    else
                    this.onAddDesordre()
                    }
                    //here you can add you code to display the coordinates or whatever you want to do
                }
            });

        }
    },


   data() { 
       return { 
       shapeList: [],
        features: [],
        lstDesordre: [
          {id:1, name:'DES-221',icon:require('@/assets/images/ficheiqoa/Warning_icon.svg.png'),center:[373.0736083984375,627.4480590820312],scale:.03},
          {id:2,name:'DES-242',icon:require('@/assets/images/ficheiqoa/Warning_icon.svg.png'),center:[705.9937744140625,603.5639038085938],scale:.03},
          {id:3,name:'DES-312',icon:require('@/assets/images/ficheiqoa/Warning_icon.svg.png'),center:[346.3052978515625,318.425537109375],scale:.03},                             
        ],
        arrayComposants: ['GU156 - A142X - Corniche','GU156 - A143V - Chaussée et revêtement du tablier','GU156 - B51 - Mur de soutènement','GU156 - C1M59 - Bordure'], 
        inDesordreData:{id:''},
        desordreData: {
          1:{
                id: 5,
                composants: 'GU156 - C1M59 - Bordure',
                type: 'Defaut',
                libelle:'DES253 - Bordure cassée',
                description:'bordure cassée sur le TPC côté Ouest',
                iqoa:"1",
                status:"En cours",
                suiteadonner:"Réparation demandée",
                author:"Veritas",
                created: '30/11/2021',
                updated: '25/12/2021',
                inspection: 'GU156 - ID du 25/11/2021',
                maintenance:'MAIN179',
                documents: [require('@/assets/images/pages/incident/image3.jpeg')],
                historique: ['30/11/2021 - Véritas - Création du désordre','25/12/2021 - ADP - Création action de maintenance associée : MAIN179'],   
              },
            2:{
              id: 4,
              composants: 'GU156 - A143V - Chaussée et revêtement du tablier',
              type: 'Alteration',
              libelle:'DES249 - Alteration revêtement',
              description:'Début de dégradation côté Nord Est  ',
              iqoa:"1",
              status:"En cours",
              suiteadonner:"Laissez en l'état",
              author:"Veritas",
              created: '30/11/2021',
              updated: '30/11/2021',
              inspection: 'GU156 - ID du 25/11/2021',
              maintenance:'',
              documents: [require('@/assets/images/pages/incident/image1.jpeg')],
              historique: ['30/11/2021 - Véritas - Création du désordre'],   
            },
            3:{
              id: 2,
              composants: 'GU156 - A142X - Corniche',
              type: 'Fissure',
              libelle:'DES245 - Fissures ouvertures',
              description:'Quelques fissures verticales d’ouverture < 0,4 mm',
              iqoa:"2",
              status:"En cours",
              suiteadonner:"Réparation demandée",
              author:"Veritas",
              created: '30/11/2021',
              updated: '25/12/2021',
              inspection: 'GU156 - ID du 25/11/2021',
              maintenance:'MAIN173',
              documents: [require('@/assets/images/pages/incident/image3.jpeg')],
              historique: ['30/11/2021 - Véritas - Création du désordre','25/12/2021 - ADP - Création action de maintenance associée : MAIN173'],   
            },
        }                       
       }
   },
   mounted() {

      /*  store
            .dispatch('app-inspection/getShapes')
            .then(response => {

                this.shapeList = response.data.shapes         
            })
            .catch((e) => {console.log(e)
            })
*/

  
    

    },
    setup() {
      const isAddNewDesordreSidebarActive = ref(false)
      return {isAddNewDesordreSidebarActive}
    },          
  };
</script>

<style>
.ouvrage-modal .modal-dialog{
    max-width: 800px;
}
.nopad th, .nopad td {
    padding: 0px;
}

.ouvrage-modal .modal-body          { overflow: auto; height: 600px;padding:0; }
.ouvrage-modal .modal-body  thead th { position: sticky; top: 0; z-index: 1; }

/* Just common table stuff. Really. */
.ouvrage-modal table  { border-collapse: collapse; width: 100%; }
.ouvrage-modal th     { background:#eee; }
.ouvrage-modal .custom-checkbox{
    margin-left: 15px;
}
</style>


