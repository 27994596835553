<template>
  <section class="app-ecommerce-details">
    <maintenance-add-new
      :is-add-new-maintenance-sidebar-active.sync="isAddNewMaintenanceSidebarActive"
      @addmaintenance='(inmaintenance) => addMaintenance(inmaintenance)'  
        :composantsList="arrayComposants"
        :inMaintenanceData="inMaintenanceData"
    />

    <type-inspection-add-new
      :is-add-new-typeins-sidebar-active.sync="isAddNewTypeinsSidebarActive"
        :composantsList="arrayComposants"
        :inTypeinsData="inTypeinsData"
    @addtypeins='(inmaintenance) => addTypeins(inmaintenance)' 
    />    

    <carac-add-new
      :is-add-new-carac-sidebar-active.sync="isAddNewCaracSidebarActive"
        :composantsList="arrayComposants"
        :inCaracData="inCaracData"
    @addcarac='(incarac) => addCarac(incarac)' 
    />     

    <contrainte-add-new
      :is-add-new-contrainte-sidebar-active.sync="isAddNewContrainteSidebarActive"
        :composantsList="arrayComposants"
        :inContrainteData="inContrainteData"
    @addcontrainte='(incontrainte) => addContrainte(incontrainte)' 
    />  

    <intervenant-add-new
      :is-add-new-intervenant-sidebar-active.sync="isAddNewIntervenantSidebarActive"
        :composantsList="arrayComposants"
        :inIntervenantData="inIntervenantData"
    @addintervenant='(inintervenant) => addIntervenant(inintervenant)' 
    />  

    <composant-add-new
      :is-add-new-composant-sidebar-active.sync="isAddNewComposantSidebarActive"
        :composantsList="arrayComposants"
        :inComposantData="inComposantData"
    @addcomposant='(incomposant) => addComposant(incomposant)' 
    />     

    <fiche-iqoa :idtoshow="ficheId"/>

    <relevereadmodal/>

    <!-- Content -->
    <b-card
      v-if="product"
      no-body
    >
      <b-card-body>
        <b-row>

          <!-- Left: Product Image Container -->
          <b-col
            cols="12"
            md="2"
            class="mb-2 mb-md-0"
          >
            <div class="d-flex align-items-center justify-content-center">
              <b-img
                :src="product.image"
                :alt="`Image of ${product.name}`"
                class="product-img"
                fluid
              />
               
            </div>
          </b-col>  
          <b-col
            cols="12"
            md="6"
            class="mb-2 mb-md-0"
          >            
            <div class="d-flex align-items-left justify-content-left">
              <h4>{{ product.name }}</h4>
            </div>
            <div class="d-flex align-items-left justify-content-left">            
                  <b-card-text class="item-company mb-0">
                    <span>Type d'ouvrage : {{ product.typeName}}</span>
                  </b-card-text>
            </div>  
            <div class="d-flex align-items-left justify-content-left">            
                <b-card-text class="item-company mb-0">
                  <span>Plateforme : {{ plateforme }}</span>
                </b-card-text>
            </div>      
            <div class="d-flex align-items-left justify-content-left">            
                <b-card-text class="item-company mb-0">
                  <span>Localisation :</span>
                </b-card-text>
            </div>  
            <div class="d-flex align-items-left justify-content-left">            
                <b-card-text class="item-company mb-0 ml-2">
                  <span>Long. : {{ product.center[0] }}</span>
                </b-card-text>
            </div>    
            <div class="d-flex align-items-left justify-content-left">            
                <b-card-text class="item-company mb-0 ml-2">
                  <span>Lat. : {{ product.center[1] }}</span>
                </b-card-text>
            </div>                                          
          </b-col>  
          <b-col
            cols="12"
            md="4"
            class="mb-2 mb-md-0"
          >              
            <div class="d-flex align-items-right justify-content-right">
              <h4>
                IQOA : 
                <div class="containeriqoa">
                  <img v-if="product.iqoa === '1'" :src="require('@/assets/images/ficheiqoa/gb.png')" height="35px" width="35px"/>
                  <img v-if="product.iqoa === '2' || product.iqoa === '2E'" :src="require('@/assets/images/ficheiqoa/yellow.png')" height="35px" width="35px"/>
                  <img v-if="product.iqoa === '3' || product.iqoa === '3U'" :src="require('@/assets/images/ficheiqoa/red.png')" height="35px" width="35px"/>
                        
                    <div class="text">
                      {{ product.iqoa }}
                    </div>
                </div>
              </h4>
            </div>      
             <div>
               <span  style="float:right">
                <b-button
                  variant="primary"
                  @click="gotoCarnet"
                  class="ml-2 mt-2"
                >
                  <span class="text-nowrap">Voir le carnet de santé</span>
                </b-button></span>
              </div>                      
          </b-col>
        </b-row>
        <b-row>
          <!-- Right: Product Details -->
          <b-col
            cols="12"
            md="12"
          >
            <div class="bar-button">
            <b-button-toolbar class="align-items-center justify-content-center">
              <b-button-group
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="outline-primary"
                  v-on:click="toogle='fiche';"
                  autofocus="autofocus"
                >
                  Fiche signalétique
                </b-button>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="outline-primary"
                  v-on:click="toogle='documentation';"
                >
                  Base documentaire
                </b-button>                 
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="outline-primary"
                  v-on:click="toogle='composant';"
                >
                  Composants
                </b-button>                
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="outline-primary"
                  v-on:click="toogle='actions';"
                >
                  Actions récurrentes
                </b-button>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="outline-primary"
                  v-on:click="toogle='contrainte';"
                >
                  Contraintes / moyen accès
                </b-button> 
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="outline-primary"
                  v-on:click="toogle='intervenant';"
                >
                  Intervenants                  
                </b-button>   
                                                              
                <!--<b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="outline-primary"
                  v-on:click="toogle='galerie';"
                >
                  Galerie
                </b-button>                
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="outline-primary"
                  v-on:click="toogle='vue';"
                >
                   Vues / Coupes
                </b-button>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="outline-primary"
                  v-on:click="toogle='document';"
                >
                  Documents
                </b-button>   -->                             
              </b-button-group>
            </b-button-toolbar> 
            </div> 
            <div class="fichecontainer" style="width:100%;height:100%;">


                <div class="fichecontent" v-if="toogle == 'fiche'">
                  <div>
                      <hr/>
                      <h6 class="mb-2">Fiche signalétique de l'ouvrage <span style="float:right"><i>(Dernière mise à jour le 25/01/2022)</i></span></h6>
                      <b-tabs pills vertical>
                          <b-tab
                            title="Identification"
                            active
                          >
                            <b-card-text>
                              <h4>Identification de l'ouvrage</h4>
                            </b-card-text>
                            <b-card-text>
                              
                                <b-row><b-col>Photo(s) de l’ouvrage de franchissement ou de soutènement (élévation)</b-col></b-row>
                                <b-row class="mb-1"><b-col><b-form-textarea id="textarea-rows" rows="4"/></b-col></b-row>

                                <b-row><b-col>Nom de l’ouvrage de franchissement ou de soutènement</b-col></b-row>
                                <b-row class="mb-1"><b-col><b-form-input/></b-col></b-row>

                                <b-row><b-col>Numéro identifiant</b-col></b-row>
                                <b-row class="mb-1"><b-col><b-form-input/></b-col></b-row>     

                                <b-row><b-col class="caractitle">Appartenance à un franchissement ou à un soutènement comportant plusieurs ouvrages</b-col></b-row>
                                    
                                <b-row class="mb-1 mt-1"><b-col><div class="form-check form-check-inline"><b-form-radio name="radio-fran" value="Oui">Oui</b-form-radio>&nbsp;&nbsp;&nbsp;&nbsp;<b-form-radio name="radio-fran" value="Non">Non</b-form-radio></div></b-col></b-row> 
                                <b-row class="mb-1"><b-col md="5">Nom du franchissement ou du soutènement le cas échéant :</b-col><b-col md="7"><b-form-input/></b-col></b-row> 
                              
                                <b-row><b-col class="caractitle">Localisation</b-col></b-row>
                                <b-row class="mb-1 mt-1"><b-col md="4">Département 1 :</b-col><b-col md="8"><b-form-input/></b-col></b-row> 
                                <b-row class="mb-1"><b-col md="4">Département 2 :</b-col><b-col md="8"><b-form-input/></b-col></b-row> 
                                <b-row class="mb-1"><b-col md="4">Commune 1 :</b-col><b-col md="8"><b-form-input/></b-col></b-row> 
                                <b-row class="mb-1"><b-col md="4">Commune 2 :</b-col><b-col md="8"><b-form-input/></b-col></b-row> 
                                <b-row class="mb-1"><b-col md="4">Commune 3 :</b-col><b-col md="8"><b-form-input/></b-col></b-row>                             
                              
                                <b-row><b-col class="caractitle">Voie de rattachement</b-col></b-row>
                                <b-row class="mb-1 mt-1"><b-col md="4">Type (autoroute, route nationale, bretelle, etc.) :</b-col><b-col md="8"><b-form-input/></b-col></b-row> 
                                <b-row class="mb-1"><b-col md="4">Nom ou numéro :</b-col><b-col md="8"><b-form-input/></b-col></b-row> 
                                <b-row class="mb-1"><b-col md="4">PR (au début de l’ouvrage) :</b-col><b-col md="8"><b-form-input/></b-col></b-row> 

                            </b-card-text>
                            
                          </b-tab>
                          <b-tab title="Données administratives">
                            <b-card-text>
                              <h4>Données administratives</h4>
                            </b-card-text>
                           <b-card-text>
                                <b-row><b-col>Maître d’ouvrage propriétaire</b-col></b-row>
                                <b-row class="mb-1"><b-col><b-form-textarea id="textarea-rows" rows="4"/></b-col></b-row>

                                <b-row><b-col>Maître d’ouvrage gestionnaire</b-col></b-row>
                                <b-row class="mb-1"><b-col><b-form-input/></b-col></b-row>

                                <b-row><b-col class="caractitle">Maîtres d’ouvrages associés</b-col></b-row>
                                <b-row class="mb-1 mt-1"><b-col md="3">Maître d’ouvrage 1 :</b-col>
                                    <b-col md="8">
                                            <b-row class="mb-1"><b-col md="6">Nom :</b-col><b-col md="6"><b-form-input/></b-col></b-row>
                                            <b-row class="mb-1"><b-col md="6">Date de la convention :</b-col><b-col md="6"><b-form-input/></b-col></b-row>
                                            <b-row class="mb-1"><b-col md="6">Parties d’ouvrage concernées :</b-col><b-col md="6"><b-form-input/></b-col></b-row>
                                    </b-col>
                                </b-row> 
                                <b-row class="mb-1"><b-col md="3">Maître d’ouvrage 2 :</b-col>
                                    <b-col md="8">
                                            <b-row class="mb-1"><b-col md="6">Nom :</b-col><b-col md="6"><b-form-input/></b-col></b-row>
                                            <b-row class="mb-1"><b-col md="6">Date de la convention :</b-col><b-col md="6"><b-form-input/></b-col></b-row>
                                            <b-row class="mb-1"><b-col md="6">Parties d’ouvrage concernées :</b-col><b-col md="6"><b-form-input/></b-col></b-row>
                                    </b-col>                                
                                </b-row> 
                                <b-row class="mb-1"><b-col md="3">Maître d’ouvrage  3 :</b-col>
                                    <b-col md="8">
                                            <b-row class="mb-1"><b-col md="6">Nom :</b-col><b-col md="6"><b-form-input/></b-col></b-row>
                                            <b-row class="mb-1"><b-col md="6">Date de la convention :</b-col><b-col md="6"><b-form-input/></b-col></b-row>
                                            <b-row class="mb-1"><b-col md="6">Parties d’ouvrage concernées :</b-col><b-col md="6"><b-form-input/></b-col></b-row>
                                    </b-col>                                
                                </b-row>  

                                <b-row><b-col class="caractitle">Trafic poids-lourds</b-col></b-row>
                                    <b-row class="mb-1 mt-1"><b-col md="2">Autorisation :</b-col><b-col md="8"><div class="form-check form-check-inline"><b-form-radio name="radio-aut" value="Oui">Oui</b-form-radio>&nbsp;&nbsp;&nbsp;&nbsp;<b-form-radio name="radio-aut" value="Non">Non</b-form-radio></div></b-col></b-row>
                                    <b-row class="mb-1 mt-1"><b-col md="6"><b-row><b-col md="4">Limité :</b-col><b-col><div class="form-check form-check-inline"><b-form-radio name="radio-lim" value="Oui">Oui</b-form-radio>&nbsp;&nbsp;&nbsp;&nbsp;<b-form-radio name="radio-lim" value="Non">Non</b-form-radio></div></b-col></b-row></b-col>
                                    <b-col><b-row><b-col><div class="form-check form-check-inline"><b-form-radio name="radio-lim" value="PTAC">PTAC</b-form-radio>&nbsp;&nbsp;&nbsp;&nbsp;<b-form-radio name="radio-lim" value="PTRA">PTRA</b-form-radio></div></b-col><b-col><b-form-input/></b-col></b-row></b-col></b-row> 

                                 <b-row><b-col class="caractitle">Transport de marchandises dangereuses</b-col></b-row>
                                 <b-row class="mb-1 mt-1"><b-col>Code « ADR - Accord européen relatif au transport international des marchandises dangereuses par route » :</b-col></b-row>
                                 <b-row class="mb-1"><b-col>  <div class="demo-inline-spacing"><b-form-checkbox value="A">A,</b-form-checkbox><b-form-checkbox value="B">B,</b-form-checkbox><b-form-checkbox value="C">C,</b-form-checkbox><b-form-checkbox value="D">D,</b-form-checkbox><b-form-checkbox value="E">E</b-form-checkbox></div></b-col></b-row>

                                 <b-row><b-col class="caractitle">Autres Trafics</b-col></b-row>
                                 <b-row class="mb-1 mt-1"><b-col md="4">Décrire s’il y a lieu :</b-col><b-col><b-form-input /></b-col></b-row>

                                 <b-row><b-col class="caractitle">Dossier d’ouvrage</b-col></b-row>
                                 <b-row class="mb-1 mt-1"><b-col md="4">Référence d’archivage :</b-col><b-col><b-form-input /></b-col></b-row> 
                                 <b-row class="mb-1 mt-1"><b-col md="4">Lieu de stockage :</b-col><b-col><b-form-input /></b-col></b-row>    


                            </b-card-text>

                          </b-tab>
                          <b-tab
                            title="Réseaux"
                          >
                            <b-card-text>
                              <h4>Réseaux</h4>
                            </b-card-text>
                           <b-card-text>
                                <b-row class="mb-1 mt-1"><b-col md="2">Réseau 1 :</b-col>
                                    <b-col md="10">
                                            <b-row class="mb-1"><b-col md="6">Nature du réseau :</b-col><b-col md="6"><b-form-input/></b-col></b-row>
                                            <b-row class="mb-1"><b-col md="6">Exploitant :</b-col><b-col md="6"><b-form-input/></b-col></b-row>
                                            <b-row class="mb-1"><b-col md="6">Concessionnaire :</b-col><b-col md="6"><b-form-input/></b-col></b-row>
                                            <b-row class="mb-1"><b-col md="6">Nature de l’autorisation et référence du document :</b-col><b-col md="6"><b-form-input/></b-col></b-row>
                                            <b-row class="mb-1"><b-col md="6">Localisation :</b-col><b-col md="6"><b-form-input/></b-col></b-row>
                                            <b-row class="mb-1"><b-col md="6">Nombre et diamètre des fourreaux ou des canalisations :</b-col><b-col md="6"><b-form-input/></b-col></b-row>                                            
                                    </b-col>
                                </b-row> 
                                <hr/>
                                <b-row class="mb-1"><b-col md="2">Réseau 2 :</b-col>
                                    <b-col md="10">
                                            <b-row class="mb-1"><b-col md="6">Nature du réseau :</b-col><b-col md="6"><b-form-input/></b-col></b-row>
                                            <b-row class="mb-1"><b-col md="6">Exploitant :</b-col><b-col md="6"><b-form-input/></b-col></b-row>
                                            <b-row class="mb-1"><b-col md="6">Concessionnaire :</b-col><b-col md="6"><b-form-input/></b-col></b-row>
                                            <b-row class="mb-1"><b-col md="6">Nature de l’autorisation et référence du document :</b-col><b-col md="6"><b-form-input/></b-col></b-row>
                                            <b-row class="mb-1"><b-col md="6">Localisation :</b-col><b-col md="6"><b-form-input/></b-col></b-row>
                                            <b-row class="mb-1"><b-col md="6">Nombre et diamètre des fourreaux ou des canalisations :</b-col><b-col md="6"><b-form-input/></b-col></b-row>   
                                    </b-col>                                
                                </b-row> 
                                <hr/>
                                <b-row class="mb-1"><b-col md="2">Réseau  3 :</b-col>
                                    <b-col md="10">
                                            <b-row class="mb-1"><b-col md="6">Nature du réseau :</b-col><b-col md="6"><b-form-input/></b-col></b-row>
                                            <b-row class="mb-1"><b-col md="6">Exploitant :</b-col><b-col md="6"><b-form-input/></b-col></b-row>
                                            <b-row class="mb-1"><b-col md="6">Concessionnaire :</b-col><b-col md="6"><b-form-input/></b-col></b-row>
                                            <b-row class="mb-1"><b-col md="6">Nature de l’autorisation et référence du document :</b-col><b-col md="6"><b-form-input/></b-col></b-row>
                                            <b-row class="mb-1"><b-col md="6">Localisation :</b-col><b-col md="6"><b-form-input/></b-col></b-row>
                                            <b-row class="mb-1"><b-col md="6">Nombre et diamètre des fourreaux ou des canalisations :</b-col><b-col md="6"><b-form-input/></b-col></b-row>   
                                    </b-col>                                
                                </b-row>  
                            </b-card-text>
                            
                          </b-tab>
                          <b-tab title="Contraintes environnementales ou administratives, sujetions particulières">
                            <b-card-text>
                              <h4>Contraintes environnementales ou administratives, sujetions particulières</h4>
                            </b-card-text>
                           <b-card-text>
                             <hr/>
                                 <b-row class="mb-1"><b-col md="3">Aléa sismique :</b-col><b-col><div class="demo-inline-spacing"><b-form-checkbox value="A">Très faible</b-form-checkbox><b-form-checkbox value="B">Faible</b-form-checkbox><b-form-checkbox value="C">Modéré</b-form-checkbox><b-form-checkbox value="D">Moyen</b-form-checkbox><b-form-checkbox value="E">Fort</b-form-checkbox></div></b-col></b-row>
                                 <hr/> 
                                 <b-row class="mb-1 mt-1"><b-col md="8">Ouvrage situé dans une zone couverte par un plan d’exposition aux risques (PER) :</b-col>   
                                 <b-col><div class="demo-inline-spacing"><b-form-radio name="radio-per" value="Oui">Oui</b-form-radio>&nbsp;&nbsp;&nbsp;&nbsp;<b-form-radio name="radio-per" value="Non">Non</b-form-radio></div></b-col></b-row> 
                                <b-row class="mb-1"><b-col md="4">Le cas échéant, préciser le(s) risque(s) :</b-col><b-col md="8"><b-form-input/></b-col></b-row>
                                <hr/>
                                <b-row class="mb-1"><b-col md="8">Ouvrage situé dans le périmètre de protection d’une zone de captage des eaux :</b-col><b-col md="4"><div class="demo-inline-spacing"><b-form-radio name="radio-pro" value="Oui">Oui</b-form-radio>&nbsp;&nbsp;&nbsp;&nbsp;<b-form-radio name="radio-pro" value="Non">Non</b-form-radio></div></b-col></b-row>
                                <hr/>
                                <b-row class="mb-1"><b-col md="8">Ouvrage classé monument historique :</b-col><b-col md="4"><div class="demo-inline-spacing"><b-form-radio name="radio-mon" value="Oui">Oui</b-form-radio>&nbsp;&nbsp;&nbsp;&nbsp;<b-form-radio name="radio-mon" value="Non">Non</b-form-radio></div></b-col></b-row>
                                <hr/>
                                <b-row class="mb-1"><b-col md="8">Ouvrage inscrit à l’inventaire supplémentaire des monuments historiques :</b-col><b-col md="4"><div class="demo-inline-spacing"><b-form-radio name="radio-sup" value="Oui">Oui</b-form-radio>&nbsp;&nbsp;&nbsp;&nbsp;<b-form-radio name="radio-sup" value="Non">Non</b-form-radio></div></b-col></b-row>
                                <hr/>
                                <b-row class="mb-1"><b-col md="8">Ouvrage situé dans un site classé :</b-col><b-col md="4"><div class="demo-inline-spacing"><b-form-radio name="radio-cla" value="Oui">Oui</b-form-radio>&nbsp;&nbsp;&nbsp;&nbsp;<b-form-radio name="radio-cla" value="Non">Non</b-form-radio></div></b-col></b-row>
                               <hr/>
                                <b-row class="mb-1"><b-col md="8">Ouvrage situé dans un site inscrit :</b-col><b-col md="4"><div class="demo-inline-spacing"><b-form-radio name="radio-ins" value="Oui">Oui</b-form-radio>&nbsp;&nbsp;&nbsp;&nbsp;<b-form-radio name="radio-ins" value="Non">Non</b-form-radio></div></b-col></b-row>
                              <hr/>
                                <b-row class="mb-1"><b-col md="8">Autres contraintes ou sujétions (voie ferrée, ouvrage situé dans un parc national ou régional, ouvrage équipé d’un repère
                                NGF, zone de protection du patrimoine architectural, urbain et paysager (ZPPAUP), Natura 2000, trame bleue, proximité
                                d’habitations, etc.) :</b-col></b-row>
                                <b-row class="mb-1"><b-col><b-form-textarea id="textarea-rows" rows="4"/></b-col></b-row>
                            </b-card-text>

                          </b-tab>          
                          <b-tab
                            title="Voies franchies ou autres obstacles (cas des ouvrages de franchissement)"
                          >
                            <b-card-text>
                              <h4>Voies franchies ou autres obstacles (cas des ouvrages de franchissement)</h4>
                            </b-card-text>
                           <b-card-text>
                             <hr/>
                             <b-row>
                             <b-col md="2">Voie franchie 1</b-col>
                             <b-col md="10">
                               <b-row class="mb-1"><b-col md="6">Nature de la voie (routière, ferroviaire, fluviale, etc.) :</b-col><b-col md="6"><b-form-input/></b-col></b-row>
                               <b-row class="mb-1"><b-col md="6">Type de la voie (autoroute, route nationale, etc.) :</b-col><b-col md="6"><b-form-input/></b-col></b-row>
                               <b-row class="mb-1"><b-col md="6">Nom ou numéro :</b-col><b-col md="6"><b-form-input/></b-col></b-row>
                               <b-row class="mb-1"><b-col md="6">Maître d’ouvrage gestionnaire :</b-col><b-col md="6"><b-form-input/></b-col></b-row>
                               <b-row class="mb-1">
                                 <b-col>Gabarit</b-col>
                                 <b-col>
                                   <b-row class="mb-1"><b-col md="4">Hauteur :</b-col><b-col md="8"><b-form-input/></b-col></b-row>
                                   <b-row><b-col md="4">Largeur :</b-col><b-col md="8"><b-form-input/></b-col></b-row>
                                 </b-col>
                               </b-row>
                             </b-col>
                             </b-row>
                             <hr/>
                             <b-row>
                             <b-col md="2">Voie franchie 2</b-col>
                             <b-col md="10">
                               <b-row class="mb-1"><b-col md="6">Nature de la voie (routière, ferroviaire, fluviale, etc.) :</b-col><b-col md="6"><b-form-input/></b-col></b-row>
                               <b-row class="mb-1"><b-col md="6">Type de la voie (autoroute, route nationale, etc.) :</b-col><b-col md="6"><b-form-input/></b-col></b-row>
                               <b-row class="mb-1"><b-col md="6">Nom ou numéro :</b-col><b-col md="6"><b-form-input/></b-col></b-row>
                               <b-row class="mb-1"><b-col md="6">Maître d’ouvrage gestionnaire :</b-col><b-col md="6"><b-form-input/></b-col></b-row>
                               <b-row class="mb-1">
                                 <b-col>Gabarit</b-col>
                                 <b-col>
                                   <b-row class="mb-1"><b-col md="4">Hauteur :</b-col><b-col md="8"><b-form-input/></b-col></b-row>
                                   <b-row><b-col md="4">Largeur :</b-col><b-col md="8"><b-form-input/></b-col></b-row>
                                 </b-col>
                               </b-row>
                             </b-col>
                             </b-row>
                             <hr/>
                             <b-row>
                             <b-col md="2">Voie franchie 3</b-col>
                             <b-col md="10">
                               <b-row class="mb-1"><b-col md="6">Nature de la voie (routière, ferroviaire, fluviale, etc.) :</b-col><b-col md="6"><b-form-input/></b-col></b-row>
                               <b-row class="mb-1"><b-col md="6">Type de la voie (autoroute, route nationale, etc.) :</b-col><b-col md="6"><b-form-input/></b-col></b-row>
                               <b-row class="mb-1"><b-col md="6">Nom ou numéro :</b-col><b-col md="6"><b-form-input/></b-col></b-row>
                               <b-row class="mb-1"><b-col md="6">Maître d’ouvrage gestionnaire :</b-col><b-col md="6"><b-form-input/></b-col></b-row>
                               <b-row class="mb-1">
                                 <b-col>Gabarit</b-col>
                                 <b-col>
                                   <b-row class="mb-1"><b-col md="4">Hauteur :</b-col><b-col md="8"><b-form-input/></b-col></b-row>
                                   <b-row><b-col md="4">Largeur :</b-col><b-col md="8"><b-form-input/></b-col></b-row>
                                 </b-col>
                               </b-row>
                             </b-col>
                             </b-row>
                             <hr/>
                                <b-row class="mb-1"><b-col md="6">Obstacles (Nature : cours d’eau, talweg, parking, etc.) :</b-col><b-col md="6"><b-form-input/></b-col></b-row>                          

                            </b-card-text>

                            
                          </b-tab>
                          <b-tab title="Voies ou éléments concernés(cas des ouvrages de soutènement)">
                            <b-card-text>
                              <h4>Voies ou éléments concernés(cas des ouvrages de soutènement)</h4>
                            </b-card-text>
                           <b-card-text>
                             <b-row>
                             <b-col md="2">Voie franchie 1</b-col>
                             <b-col md="10">
                               <b-row class="mb-1"><b-col md="6">Nature de la voie (routière, ferroviaire, fluviale, etc.) :</b-col><b-col md="6"><b-form-input/></b-col></b-row>
                               <b-row class="mb-1"><b-col md="6">Type de la voie (autoroute, route nationale, etc.) :</b-col><b-col md="6"><b-form-input/></b-col></b-row>
                               <b-row class="mb-1"><b-col md="6">Nom ou numéro :</b-col><b-col md="6"><b-form-input/></b-col></b-row>
                               <b-row class="mb-1"><b-col md="6">Maître d’ouvrage gestionnaire :</b-col><b-col md="6"><b-form-input/></b-col></b-row>
                               <b-row class="mb-1">
                                 <b-col>Gabarit</b-col>
                                 <b-col>
                                   <b-row class="mb-1"><b-col md="4">Hauteur :</b-col><b-col md="8"><b-form-input/></b-col></b-row>
                                   <b-row><b-col md="4">Largeur :</b-col><b-col md="8"><b-form-input/></b-col></b-row>
                                 </b-col>
                               </b-row>
                             </b-col>
                             </b-row>
                             <hr/>
                             <b-row>
                             <b-col md="2">Voie franchie 2</b-col>
                             <b-col md="10">
                               <b-row class="mb-1"><b-col md="6">Nature de la voie (routière, ferroviaire, fluviale, etc.) :</b-col><b-col md="6"><b-form-input/></b-col></b-row>
                               <b-row class="mb-1"><b-col md="6">Type de la voie (autoroute, route nationale, etc.) :</b-col><b-col md="6"><b-form-input/></b-col></b-row>
                               <b-row class="mb-1"><b-col md="6">Nom ou numéro :</b-col><b-col md="6"><b-form-input/></b-col></b-row>
                               <b-row class="mb-1"><b-col md="6">Maître d’ouvrage gestionnaire :</b-col><b-col md="6"><b-form-input/></b-col></b-row>
                               <b-row class="mb-1">
                                 <b-col>Gabarit</b-col>
                                 <b-col>
                                   <b-row class="mb-1"><b-col md="4">Hauteur :</b-col><b-col md="8"><b-form-input/></b-col></b-row>
                                   <b-row><b-col md="4">Largeur :</b-col><b-col md="8"><b-form-input/></b-col></b-row>
                                 </b-col>
                               </b-row>
                             </b-col>
                             </b-row>
                             <hr/>
                             <b-row>
                             <b-col md="2">Voie franchie 3</b-col>
                             <b-col md="10">
                               <b-row class="mb-1"><b-col md="6">Nature de la voie (routière, ferroviaire, fluviale, etc.) :</b-col><b-col md="6"><b-form-input/></b-col></b-row>
                               <b-row class="mb-1"><b-col md="6">Type de la voie (autoroute, route nationale, etc.) :</b-col><b-col md="6"><b-form-input/></b-col></b-row>
                               <b-row class="mb-1"><b-col md="6">Nom ou numéro :</b-col><b-col md="6"><b-form-input/></b-col></b-row>
                               <b-row class="mb-1"><b-col md="6">Maître d’ouvrage gestionnaire :</b-col><b-col md="6"><b-form-input/></b-col></b-row>
                               <b-row class="mb-1">
                                 <b-col>Gabarit</b-col>
                                 <b-col>
                                   <b-row class="mb-1"><b-col md="4">Hauteur :</b-col><b-col md="8"><b-form-input/></b-col></b-row>
                                   <b-row><b-col md="4">Largeur :</b-col><b-col md="8"><b-form-input/></b-col></b-row>
                                 </b-col>
                               </b-row>
                             </b-col>
                             </b-row>
                             <hr/>
                                <b-row class="mb-1"><b-col md="6">Eléments concernés (Nature : cours d’eau, habitation, parking, etc.) :</b-col><b-col md="6"><b-form-input/></b-col></b-row>                          

                            </b-card-text>

                          </b-tab>   
                         <b-tab
                            title="Description générale"
                          >
                            <b-card-text>
                              <h4>Description générale</h4>
                            </b-card-text>
                           <b-card-text>
                             <hr/>
                               <b-row class="mb-1"><b-col md="4">Type d'ouvrage :</b-col><b-col md="8"><b-form-input/></b-col></b-row>
                               <hr/>
                               <b-row class="mb-1">
                                 <b-col md="2">Construction</b-col>
                                 <b-col>
                                   <b-row class="mb-1"><b-col md="5">Date de construction :</b-col><b-col md="7"><b-form-input/></b-col></b-row>
                                   <b-row class="mb-1"><b-col md="5">Maître d’œuvre en phase travaux :</b-col><b-col md="7"><b-form-input/></b-col></b-row>
                                   <b-row><b-col md="5">Entreprise mandataire du groupement :</b-col><b-col md="7"><b-form-input/></b-col></b-row>
                                 </b-col>
                               </b-row>
                               <hr/>
                                <b-row class="mb-1"><b-col md="4">Matériaux :</b-col><b-col md="8"><b-form-input/></b-col></b-row>

                                <b-row><b-col class="caractitle">Caractéristiques principales pour les ouvrages de franchissement</b-col></b-row>
                                <b-row class="mb-1 mt-1"><b-col md="4">Longueur totale :</b-col><b-col md="8"><b-form-input/></b-col></b-row> 
                                <b-row class="mb-1"><b-col md="4">Nombre de travées :</b-col><b-col md="8"><b-form-input/></b-col></b-row> 
                                <b-row class="mb-1"><b-col md="4">portée maxi :</b-col><b-col md="8"><b-form-input/></b-col></b-row> 
                                <b-row class="mb-1"><b-col md="4">Largeur :</b-col><b-col md="8"><b-form-input/></b-col></b-row> 
                                <b-row class="mb-1"><b-col md="4">Biais :</b-col><b-col md="8"><b-form-input/></b-col></b-row> 
                                <b-row class="mb-1"><b-col md="4">Autres (courbures, tirant d’air, mode de construction, etc.) :</b-col><b-col md="8"><b-form-input/></b-col></b-row>    

                                <b-row><b-col class="caractitle">Caractéristiques principales pour les ouvrages de soutènement</b-col></b-row>
                                <b-row class="mb-1 mt-1"><b-col md="4">Longueur totale :</b-col><b-col md="8"><b-form-input/></b-col></b-row> 
                                <b-row class="mb-1"><b-col md="4">Hauteur maximale :</b-col><b-col md="8"><b-form-input/></b-col></b-row> 
                                <b-row class="mb-1"><b-col md="4">Autres :</b-col><b-col md="8"><b-form-input/></b-col></b-row>
                                
                            </b-card-text>

                            
                          </b-tab>
                          <b-tab title="Description de la structure">
                            <b-card-text>
                              <h4>Description de la structure</h4>
                            </b-card-text>
                           <b-card-text>
                             <hr/>
                              <p>Par convention, la numérotation (des travées, des piles, des buses, des voûtes, des parties de mur, etc.) est dans
                              le sens des PR croissants. Il appartient au maître d’ouvrage constructeur de prendre en compte cette convention
                              dans la numérotation des éléments dès la phase de conception.</p>
                              <hr/>
                                <b-row><b-col class="caractitle">Cas des ouvrages de franchissement avec tablier</b-col></b-row>
                                <b-row class="mb-1 mt-1"><b-col md="3">N° de travée (s) :</b-col><b-col md="9">
                                  <b-row class="mb-1"><b-col md="2">Longueur :</b-col><b-col md="10"><b-form-input/></b-col></b-row>
                                   <b-row><b-col md="2">Matériau :</b-col><b-col md="10"><b-form-input/></b-col></b-row>
                                  </b-col></b-row> 
                                  <hr/>
                                 <b-row class="mb-1 mt-1"><b-col md="3">N° de travée (s) :</b-col><b-col md="9">
                                  <b-row class="mb-1"><b-col md="2">Longueur :</b-col><b-col md="10"><b-form-input/></b-col></b-row>
                                   <b-row><b-col md="2">Matériau :</b-col><b-col md="10"><b-form-input/></b-col></b-row>
                                  </b-col></b-row>
                                  <hr/>
                                 <b-row class="mb-1 mt-1"><b-col md="3">N° de travée (s) :</b-col><b-col md="9">
                                  <b-row class="mb-1"><b-col md="2">Longueur :</b-col><b-col md="10"><b-form-input/></b-col></b-row>
                                   <b-row><b-col md="2">Matériau :</b-col><b-col md="10"><b-form-input/></b-col></b-row>
                                  </b-col></b-row>

                                <b-row><b-col class="caractitle">Cas des ouvrages de franchissement voûtés ou busés</b-col></b-row>
                                <b-row class="mb-1 mt-1"><b-col md="4">N° de buse (s) ou de voûte (s) :</b-col><b-col md="8">
                                  <b-row class="mb-1"><b-col md="5">Ouverture :</b-col><b-col md="7"><b-form-input/></b-col></b-row>
                                   <b-row class="mb-1"><b-col md="5">Hauteur à la clé :</b-col><b-col md="7"><b-form-input/></b-col></b-row>
                                   <b-row class="mb-1"><b-col md="5">Hauteur du remblai au-dessus de l’ouvrage :</b-col><b-col md="7"><b-form-input/></b-col></b-row>
                                   <b-row class="mb-1"><b-col md="5">Forme :</b-col><b-col md="7"><b-form-input/></b-col></b-row>
                                   <b-row><b-col md="5">Matériau :</b-col><b-col md="7"><b-form-input/></b-col></b-row>
                                  </b-col></b-row> 
                                  <hr/>
                               <b-row class="mb-1 mt-1"><b-col md="4">N° de buse (s) ou de voûte (s) :</b-col><b-col md="8">
                                  <b-row class="mb-1"><b-col md="5">Ouverture :</b-col><b-col md="7"><b-form-input/></b-col></b-row>
                                   <b-row class="mb-1"><b-col md="5">Hauteur à la clé :</b-col><b-col md="7"><b-form-input/></b-col></b-row>
                                   <b-row class="mb-1"><b-col md="5">Hauteur du remblai au-dessus de l’ouvrage :</b-col><b-col md="7"><b-form-input/></b-col></b-row>
                                   <b-row class="mb-1"><b-col md="5">Forme :</b-col><b-col md="7"><b-form-input/></b-col></b-row>
                                   <b-row><b-col md="5">Matériau :</b-col><b-col md="7"><b-form-input/></b-col></b-row>
                                  </b-col></b-row> 
                                  <hr/>
                               <b-row class="mb-1 mt-1"><b-col md="4">N° de buse (s) ou de voûte (s) :</b-col><b-col md="8">
                                  <b-row class="mb-1"><b-col md="5">Ouverture :</b-col><b-col md="7"><b-form-input/></b-col></b-row>
                                   <b-row class="mb-1"><b-col md="5">Hauteur à la clé :</b-col><b-col md="7"><b-form-input/></b-col></b-row>
                                   <b-row class="mb-1"><b-col md="5">Hauteur du remblai au-dessus de l’ouvrage :</b-col><b-col md="7"><b-form-input/></b-col></b-row>
                                   <b-row class="mb-1"><b-col md="5">Forme :</b-col><b-col md="7"><b-form-input/></b-col></b-row>
                                   <b-row><b-col md="5">Matériau :</b-col><b-col md="7"><b-form-input/></b-col></b-row>
                                  </b-col></b-row>     


                               <b-row><b-col class="caractitle">Culées (cas des ouvrages de franchissement avec culées)</b-col></b-row>
                                <b-row class="mb-1 mt-1">
                                  <b-col md="4" >
                                  <b-row><b-col md="5">N° de culée :</b-col><b-col md="7"><b-form-input/></b-col></b-row>
                                  </b-col>
                                  <b-col md="8">
                                  <b-row><b-col md="2">Matériau :</b-col><b-col md="10"><b-form-input/></b-col></b-row>
                                  </b-col>                                  
                                </b-row> 
                                <b-row class="mb-1 mt-1">
                                  <b-col md="4" >
                                  <b-row><b-col md="5">N° de culée :</b-col><b-col md="7"><b-form-input/></b-col></b-row>
                                  </b-col>
                                  <b-col md="8">
                                  <b-row><b-col md="2">Matériau :</b-col><b-col md="10"><b-form-input/></b-col></b-row>
                                  </b-col>                                  
                                </b-row>

                               <b-row><b-col class="caractitle">Piles (cas des ouvrages de franchissement avec piles)</b-col></b-row>
                                <b-row class="mb-1 mt-1">
                                  <b-col md="4" >
                                  <b-row><b-col md="5">N° de pile :</b-col><b-col md="7"><b-form-input/></b-col></b-row>
                                  </b-col>
                                  <b-col md="8">
                                  <b-row><b-col md="2">Matériau :</b-col><b-col md="10"><b-form-input/></b-col></b-row>
                                  </b-col>                                  
                                </b-row> 
                                <b-row class="mb-1 mt-1">
                                  <b-col md="4" >
                                  <b-row><b-col md="5">N° de pile :</b-col><b-col md="7"><b-form-input/></b-col></b-row>
                                  </b-col>
                                  <b-col md="8">
                                  <b-row><b-col md="2">Matériau :</b-col><b-col md="10"><b-form-input/></b-col></b-row>
                                  </b-col>                                  
                                </b-row>
                                <b-row class="mb-1 mt-1">
                                  <b-col md="4" >
                                  <b-row><b-col md="5">N° de pile :</b-col><b-col md="7"><b-form-input/></b-col></b-row>
                                  </b-col>
                                  <b-col md="8">
                                  <b-row><b-col md="2">Matériau :</b-col><b-col md="10"><b-form-input/></b-col></b-row>
                                  </b-col>                                  
                                </b-row>

                               <b-row><b-col class="caractitle">Cas des ouvrages de franchissement avec appareils d’appui</b-col></b-row>
                                <b-row class="mb-1 mt-1">
                                  <b-col md="4" >
                                  <b-row><b-col md="5">N° d'appui :</b-col><b-col md="7"><b-form-input/></b-col></b-row>
                                  </b-col>
                                  <b-col md="8">
                                  <b-row><b-col md="2">Type d’appareil d’appui :</b-col><b-col md="10"><b-form-input/></b-col></b-row>
                                  </b-col>                                  
                                </b-row> 
                                <b-row class="mb-1 mt-1">
                                  <b-col md="4" >
                                  <b-row><b-col md="5">N° d'appui :</b-col><b-col md="7"><b-form-input/></b-col></b-row>
                                  </b-col>
                                  <b-col md="8">
                                  <b-row><b-col md="2">Type d’appareil d’appui :</b-col><b-col md="10"><b-form-input/></b-col></b-row>
                                  </b-col>                                  
                                </b-row>
                                <b-row class="mb-1 mt-1">
                                  <b-col md="4" >
                                  <b-row><b-col md="5">N° d'appui :</b-col><b-col md="7"><b-form-input/></b-col></b-row>
                                  </b-col>
                                  <b-col md="8">
                                  <b-row><b-col md="2">Type d’appareil d’appui :</b-col><b-col md="10"><b-form-input/></b-col></b-row>
                                  </b-col>                                  
                                </b-row>

                               <b-row><b-col class="caractitle">Chape d’étanchéité (cas des ouvrages de franchissement et de certains ouvrages de soutènement)</b-col></b-row>
                                <b-row class="mb-1 mt-1"><b-col md="5">Type et appellation :</b-col><b-col md="7"><b-form-input/></b-col></b-row>
                                <b-row class="mb-1"><b-col md="5">Épaisseur de calcul chape + chaussée :</b-col><b-col md="7"><b-form-input/></b-col></b-row>

                               <b-row><b-col class="caractitle">Cas des ouvrages de soutènement</b-col></b-row>
                                <b-row class="mb-1 mt-1"><b-col md="5">Type d’ouvrage de soutènement (ou de mur) :</b-col><b-col md="7"><b-form-input/></b-col></b-row>
                                <b-row class="mb-1"><b-col md="5">Position de l’ouvrage de soutènement
                                                              (par rapport à la voie de rattachement,
                                                              sens des PR croissants)</b-col>
                                                              <b-col md="7">
                                                                <b-row><b-col><div class="demo-inline-spacing"><b-form-checkbox value="A">A gauche,</b-form-checkbox><b-form-checkbox value="C">au milieu (voies séparées),</b-form-checkbox><b-form-checkbox value="F">A droite</b-form-checkbox></div></b-col></b-row>
                                                                <b-row><b-col><div class="demo-inline-spacing"><b-form-checkbox value="A">soutient la voie,</b-form-checkbox><b-form-checkbox value="C">Protège la voie</b-form-checkbox></div></b-col></b-row>
                                                                <b-row><b-col><div class="demo-inline-spacing"><b-form-checkbox value="A">soutènement à l’intérieur d’un lacet</b-form-checkbox></div></b-col></b-row>
                                                                <b-row><b-col><div class="demo-inline-spacing"><b-form-checkbox value="A">autres cas :</b-form-checkbox></div></b-col><b-col><b-form-input/></b-col></b-row>                                                                
                                                                </b-col></b-row>   
                                <b-row class="mb-1"><b-col md="5">Longueur :</b-col><b-col md="7"><b-form-input/></b-col></b-row>
                                <b-row class="mb-1"><b-col md="5">Hauteur maximale hors sol, hors dispositif de retenue :</b-col><b-col md="7"><b-form-input/></b-col></b-row>
                                <b-row class="mb-1"><b-col md="5">Hauteur minimale hors sol, hors dispositif de retenue :</b-col><b-col md="7"><b-form-input/></b-col></b-row>
                                <b-row class="mb-1"><b-col md="5">Surface hors-sol totale :</b-col><b-col md="7"><b-form-input/></b-col></b-row>        


                               <b-row><b-col class="caractitle">Fondations (cas des ouvrages de franchissement et des ouvrages de soutènement)</b-col></b-row>
                                <b-row class="mb-1 mt-1">
                                        <b-col md="5">
                                          <b-row><b-col>N° des appuis pour les ouvrages de franchissement :</b-col><b-col><b-form-input/></b-col></b-row>
                                          <b-row><b-col>N° de la partie de mur pour les ouvrages de soutènements :</b-col><b-col><b-form-input/></b-col></b-row>
                                          </b-col>
                                        <b-col md="7">
                                          <b-row><b-col>Type de fondation :</b-col><b-col><b-form-input/></b-col></b-row>
                                          <b-row>
                                            <b-col>
                                            <b-row class="mb-1">
                                              <b-col>Fondation en site aquatique :</b-col>
                                              <b-col><div class="demo-inline-spacing"><b-form-radio name="radio-fon" value="Oui">Oui</b-form-radio>&nbsp;&nbsp;&nbsp;&nbsp;<b-form-radio name="radio-fon" value="Non">Non</b-form-radio></div></b-col></b-row>
                                            <b-row class="mb-1"><b-col>Le cas échéant</b-col></b-row>
                                            <b-row class="mb-1" ><b-col>Affouillement possible :</b-col><b-col><b-form-input/></b-col></b-row>
                                            <b-row><b-col>Type de protection :</b-col><b-col><b-form-input/></b-col></b-row>
                                            </b-col>
                                          </b-row>
                                        </b-col>
                                </b-row>
                                 <hr/>                     
                                <b-row class="mb-1 mt-1">
                                        <b-col md="5">
                                          <b-row><b-col>N° des appuis pour les ouvrages de franchissement :</b-col><b-col><b-form-input/></b-col></b-row>
                                          <b-row><b-col>N° de la partie de mur pour les ouvrages de soutènements :</b-col><b-col><b-form-input/></b-col></b-row>
                                          </b-col>
                                        <b-col md="7">
                                          <b-row><b-col>Type de fondation :</b-col><b-col><b-form-input/></b-col></b-row>
                                          <b-row>
                                            <b-col>
                                            <b-row class="mb-1">
                                              <b-col>Fondation en site aquatique :</b-col>
                                              <b-col><div class="demo-inline-spacing"><b-form-radio name="radio-fon" value="Oui">Oui</b-form-radio>&nbsp;&nbsp;&nbsp;&nbsp;<b-form-radio name="radio-fon" value="Non">Non</b-form-radio></div></b-col></b-row>
                                            <b-row class="mb-1"><b-col>Le cas échéant</b-col></b-row>
                                            <b-row class="mb-1" ><b-col>Affouillement possible :</b-col><b-col><b-form-input/></b-col></b-row>
                                            <b-row><b-col>Type de protection :</b-col><b-col><b-form-input/></b-col></b-row>
                                            </b-col>
                                          </b-row>
                                        </b-col>
                                </b-row>
                                 <hr/>
                                <b-row class="mb-1 mt-1">
                                        <b-col md="5">
                                          <b-row><b-col>N° des appuis pour les ouvrages de franchissement :</b-col><b-col><b-form-input/></b-col></b-row>
                                          <b-row><b-col>N° de la partie de mur pour les ouvrages de soutènements :</b-col><b-col><b-form-input/></b-col></b-row>
                                          </b-col>
                                        <b-col md="7">
                                          <b-row><b-col>Type de fondation :</b-col><b-col><b-form-input/></b-col></b-row>
                                          <b-row>
                                            <b-col>
                                            <b-row class="mb-1">
                                              <b-col>Fondation en site aquatique :</b-col>
                                              <b-col><div class="demo-inline-spacing"><b-form-radio name="radio-fon" value="Oui">Oui</b-form-radio>&nbsp;&nbsp;&nbsp;&nbsp;<b-form-radio name="radio-fon" value="Non">Non</b-form-radio></div></b-col></b-row>
                                            <b-row class="mb-1"><b-col>Le cas échéant</b-col></b-row>
                                            <b-row class="mb-1" ><b-col>Affouillement possible :</b-col><b-col><b-form-input/></b-col></b-row>
                                            <b-row><b-col>Type de protection :</b-col><b-col><b-form-input/></b-col></b-row>
                                            </b-col>
                                          </b-row>
                                        </b-col>
                                </b-row>                                                                                         
                                <hr/>
                                <b-row class="mb-1"><b-col md="8">Autres informations sur la description de la structure
                                        (cas des ouvrages de franchissement et de soutènement)
                                        Ex. : fonctionnement de l’ouvrage, protections anti-corrosion, pile-culée, etc.</b-col></b-row>
                                <b-row class="mb-1"><b-col><b-form-textarea id="textarea-rows" rows="4"/></b-col></b-row>
                            </b-card-text>

                          </b-tab>  
                         <b-tab
                            title="Dimensionnement"
                          >
                            <b-card-text>
                              <h4>Dimensionnement</h4>
                            </b-card-text>
                           <b-card-text>
                              <b-row class="mb-1"><b-col md="4">Règlements de calcul :</b-col><b-col md="8"><b-form-input/></b-col></b-row>
                              <b-row class="mb-1"><b-col md="4">Règlements de charge :</b-col><b-col md="8"><b-form-input/></b-col></b-row>
                              <hr/>
                              <b-row class="mb-1"><b-col md="4">Si calcul aux Eurocodes</b-col>
                                    <b-col md="8">
                                      <b-row><b-col md="8">Durée de vie définie par le maître d’ouvrage constructeur :</b-col><b-col md="4"><b-form-input/></b-col></b-row>
                                      <b-row><b-col md="4">Classe de trafic :</b-col><b-col md="8"><div class="demo-inline-spacing"><b-form-checkbox value="A">1re classe</b-form-checkbox><b-form-checkbox value="C">2e classe</b-form-checkbox></div></b-col></b-row>
                                    </b-col>
                                </b-row>
                              <hr/>
                              <b-row class="mb-1"><b-col md="4">Convoi militaire :</b-col><b-col md="8"><b-form-input/></b-col></b-row>
                              <b-row class="mb-1"><b-col md="4">Convoi exceptionnel :</b-col><b-col md="8"><b-form-input/></b-col></b-row>
                              <b-row class="mb-1"><b-col md="4">Autres charges prises en compte dans le calcul :</b-col><b-col md="8"><b-form-input/></b-col></b-row>
                            </b-card-text>

                            
                          </b-tab>
                          <b-tab title="Equipement d'exploitation et de sécurité">
                            <b-card-text>
                              <h4>Equipement d'exploitation et de sécurité</h4>
                            </b-card-text>
                           <b-card-text>
                              <p>Ne sont pas précisés dans ce document les équipements de la route (exploitation, signalisation, etc.), dont la fonction est indépendante de l’ouvrage.</p>
                              <b-row><b-col class="caractitle">Joints de dilatation (cas des ouvrages de franchissement)</b-col></b-row>
                              <b-row class="mb-1 mt-1"><b-col md="4">Joints de chaussée 1</b-col>
                                    <b-col md="8">
                                      <b-row class="mb-1"><b-col md="4">Longueur totale :</b-col><b-col md="8"><b-form-input/></b-col></b-row>
                                      <b-row class="mb-1"><b-col md="4">Type et appellation :</b-col><b-col md="8"><b-form-input/></b-col></b-row>
                                      <b-row class="mb-1"><b-col md="4">Souffle :</b-col><b-col md="8"><b-form-input/></b-col></b-row>
                                    </b-col>
                              </b-row>
                              <hr/>
                              <b-row class="mb-1 mt-1"><b-col md="4">Joints de chaussée 2</b-col>
                                    <b-col md="8">
                                      <b-row class="mb-1"><b-col md="4">Longueur totale :</b-col><b-col md="8"><b-form-input/></b-col></b-row>
                                      <b-row class="mb-1"><b-col md="4">Type et appellation :</b-col><b-col md="8"><b-form-input/></b-col></b-row>
                                      <b-row class="mb-1"><b-col md="4">Souffle :</b-col><b-col md="8"><b-form-input/></b-col></b-row>
                                    </b-col>
                              </b-row>
                              <hr/>
                              <b-row class="mb-1 mt-1"><b-col md="4">Joints de chaussée 3</b-col>
                                    <b-col md="8">
                                      <b-row class="mb-1"><b-col md="4">Longueur totale :</b-col><b-col md="8"><b-form-input/></b-col></b-row>
                                      <b-row class="mb-1"><b-col md="4">Type et appellation :</b-col><b-col md="8"><b-form-input/></b-col></b-row>
                                      <b-row class="mb-1"><b-col md="4">Souffle :</b-col><b-col md="8"><b-form-input/></b-col></b-row>
                                    </b-col>
                              </b-row>
                              <hr/>
                               <b-row class="mb-1 mt-1"><b-col md="4">Joints de chaussée 4</b-col>
                                    <b-col md="8">
                                      <b-row class="mb-1"><b-col md="4">Longueur totale :</b-col><b-col md="8"><b-form-input/></b-col></b-row>
                                      <b-row class="mb-1"><b-col md="4">Type et appellation :</b-col><b-col md="8"><b-form-input/></b-col></b-row>
                                      <b-row class="mb-1"><b-col md="4">Souffle :</b-col><b-col md="8"><b-form-input/></b-col></b-row>
                                    </b-col>
                              </b-row>
                              <b-row><b-col class="caractitle">Dalles de transition (cas des ouvrages de franchissement)</b-col></b-row>
                              <b-row class="mb-1"><b-col ><div class="demo-inline-spacing"><b-form-radio name="radio-chu" value="Oui">Oui</b-form-radio>&nbsp;&nbsp;&nbsp;&nbsp;<b-form-radio name="radio-chu" value="Non">Non</b-form-radio></div></b-col></b-row> 

                              <b-row><b-col class="caractitle">Dispositifs de retenue des véhicules et des piétons</b-col></b-row>
                              <b-row class="mb-1 mt-1"><b-col md="4">Type</b-col>
                                    <b-col md="8">
                                      <b-row class="mb-1"><b-col md="7">Gauche (ou en tête de mur pour les ouvrages de soutènement) :</b-col><b-col md="5"><b-form-input/></b-col></b-row>
                                      <b-row class="mb-1"><b-col md="7">Terre-Plein Central (TPC) gauche (s’il y a lieu) :</b-col><b-col md="5"><b-form-input/></b-col></b-row>
                                      <b-row class="mb-1"><b-col md="7">TPC droit (s’il y a lieu) :</b-col><b-col md="5"><b-form-input/></b-col></b-row>
                                      <b-row class="mb-1"><b-col md="7">Droit :</b-col><b-col md="5"><b-form-input/></b-col></b-row>
                                    </b-col>
                              </b-row>
                              <b-row class="mb-1 mt-1"><b-col md="4">Longueur</b-col>
                                    <b-col md="8">
                                      <b-row class="mb-1"><b-col md="7">Gauche (ou en tête de mur pour les ouvrages de soutènement) :</b-col><b-col md="5"><b-form-input/></b-col></b-row>
                                      <b-row class="mb-1"><b-col md="7">TPC gauche (s’il y a lieu) :</b-col><b-col md="5"><b-form-input/></b-col></b-row>
                                      <b-row class="mb-1"><b-col md="7">TPC droit (s’il y a lieu) :</b-col><b-col md="5"><b-form-input/></b-col></b-row>
                                      <b-row class="mb-1"><b-col md="7">Droit :</b-col><b-col md="5"><b-form-input/></b-col></b-row>
                                    </b-col>
                              </b-row>
                              <b-row><b-col class="caractitle">Dispositifs d’assainissement et de drainage (avaloir, corniche caniveau, fil d’eau avec regard externe à l’ouvrage, autres)</b-col></b-row>
                               <b-row class="mb-1 mt-1"><b-col><b-form-textarea id="textarea-rows" rows="4"/></b-col></b-row>
                              <b-row><b-col class="caractitle">Autres équipements (corniches, écrans anti-bruit, dispositifs anti-déversement, équipements particuliers au-dessus des voies ferrées, dispositifs de visite, etc.)</b-col></b-row>
                               <b-row class="mb-1 mt-1"><b-col><b-form-textarea id="textarea-rows" rows="4"/></b-col></b-row>
                            </b-card-text>

                          </b-tab>   
                         <b-tab
                            title="Garanties générales et particulières"
                          >
                            <b-card-text>
                              <h4>Garanties générales et particulières</h4>
                            </b-card-text>
                           <b-card-text>
                              <b-row class="mb-1"><b-col md="4">Date d’effet de la réception :</b-col><b-col md="8"><b-form-input/></b-col></b-row>
                              <hr/>
                              <b-row class="mb-1 mt-1"><b-col md="3">Garantie 1</b-col>
                                    <b-col md="9">
                                      <b-row class="mb-1"><b-col md="4">Objet de la garantie :</b-col><b-col md="8"><b-form-input/></b-col></b-row>
                                      <b-row class="mb-1"><b-col md="4">Date de fin de la garantie :</b-col><b-col md="8"><b-form-input/></b-col></b-row>
                                    </b-col>
                              </b-row>
                              <hr/>
                              <b-row class="mb-1 mt-1"><b-col md="3">Garantie 2</b-col>
                                    <b-col md="9">
                                      <b-row class="mb-1"><b-col md="4">Objet de la garantie :</b-col><b-col md="8"><b-form-input/></b-col></b-row>
                                      <b-row class="mb-1"><b-col md="4">Date de fin de la garantie :</b-col><b-col md="8"><b-form-input/></b-col></b-row>
                                    </b-col>
                              </b-row>
                              <hr/>
                              <b-row class="mb-1 mt-1"><b-col md="3">Garantie 3</b-col>
                                    <b-col md="9">
                                      <b-row class="mb-1"><b-col md="4">Objet de la garantie :</b-col><b-col md="8"><b-form-input/></b-col></b-row>
                                      <b-row class="mb-1"><b-col md="4">Date de fin de la garantie :</b-col><b-col md="8"><b-form-input/></b-col></b-row>
                                    </b-col>
                              </b-row>                           
                            </b-card-text>

                            
                          </b-tab>
                          <b-tab title="Surveillance - Vie de l'ouvrage">
                            <b-card-text>
                              <h4>Surveillance - Vie de l'ouvrage</h4>
                            </b-card-text>
                           <b-card-text>
                              <p>Inspection Détaillée Initiale (IDI)</p>
                              <b-row class="mb-1"><b-col md="4">Date :</b-col><b-col md="8"><b-form-input/></b-col></b-row>
                              <hr/>
                              <p>Ouvrage soumis à inspection détaillée</p>
                              <b-row class="mb-1"><b-col md="4">Si oui, périodicité préconisée :</b-col><b-col md="8"><b-form-input/></b-col></b-row>
                              <hr/>
                              <p>Opérations spécifiques de surveillance préconisées (ex. : inspections subaquatiques)</p>
                              <b-row class="mb-1 mt-1"><b-col><b-form-textarea id="textarea-rows" rows="4"/></b-col></b-row>
                              <hr/>
                               <p>Préciser le cas échéant la nature, l’emplacement, le fonctionnement des appareillages de mesure installés à demeure et la périodicité préconisée de la surveillance</p>
                              <b-row class="mb-1 mt-1"><b-col><b-form-textarea id="textarea-rows" rows="4"/></b-col></b-row>
                              <hr/>                             
                            </b-card-text>
                          </b-tab>                                       
                        </b-tabs>
                      <!--<h6 class="mb-2">Caractéristiques techniques de l'ouvrage<span style="float:right"><b-link @click="onCaracNew">Ajouter une caractéristique</b-link></span></h6>-->
<!--
                    <b-table
                        ref="refUserListTable"
                        class="position-relative"
                        style="width:80%;margin:auto"
                        :items="itemsCarac"
                        responsive
                        :fields="fieldsCarac"
                        primary-key="id"
                        show-empty
                        empty-text="No matching records found"
                      >

                        <template #cell(carac)="data">
                        <div class="text-nowrap">
                            <span class="align-text-top text-capitalize text-primary">{{ data.item.carac }}</span>
                          </div>          
                              
                        </template>

                        <template #cell(valeur)="data">
                          <div class="text-nowrap">
                            <b-form-input type="text" :value="data.item.valeur"></b-form-input>
                          </div>
                        </template>

                      </b-table>-->
  
                  </div>
                </div>
                <div class="fichecontent" v-if="toogle == 'composant'">
                                    <div>
                      <hr/>
                      <h6 classs="mb-2">Equipements sur ouvrage<span style="float:right"><b-link @click="onComposantNew">Ajouter un composant</b-link></span></h6>

                    <b-table
                        ref="refUserListTable"
                        class="position-relative"
                        :items="Composants.itemsComposant"
                        responsive
                        :fields="fieldsComposant"
                        primary-key="id"
                        show-empty
                        empty-text="No matching records found"
                      >

                        <template #cell(releve)="data">
                          <div class="text-nowrap">
                            <b-form-checkbox value="1" checked="1" ></b-form-checkbox>
                          </div>
                        </template>

                        <!-- Column: Role -->
                        <template #cell(type)="data">
                          <div class="text-nowrap">
                            <span class="align-text-top text-capitalize text-primary">{{ data.item.type }}</span>
                          </div>
                        </template>

                        <template #cell(nature)="data">
                        <div class="text-nowrap"  style="width:400px">
                            <b-form-input type="text" :value="data.item.nature"></b-form-input>
                          </div>          
                              
                        </template>

                        <!-- Column: Role -->
                        <template #cell(iqoa)="data">
                          <div class="text-nowrap" style="width:100px">
                            <v-select
                              v-model="data.item.iqoa"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :options=iqoas
                              :clearable="false"
                              input-id="iqoa"
                            />
                          </div>
                        </template>

                        <template #cell(fiche)="data">
                        <div class="text-nowrap">
                            <span class="align-text-top text-capitalize" v-if="data.item.fiche != ''"><b-link @click="showFiche(data.item.fiche)">Voir la fiche</b-link></span>
                          </div>          
                              
                        </template>      

                        <template #cell(releve2)="data">
                        <div class="text-nowrap">
                            <span class="align-text-top text-capitalize" v-if="data.item.releve2 === '1'"><b-link @click="showReleve2()">Voir le relevé</b-link></span>
                          </div>          
                              
                        </template>                                         

                      </b-table>
                      <hr/>
                      <h6 classs="mb-2">Equipements sous ouvrage<span style="float:right"><b-link @click="onComposantNew">Ajouter un composant</b-link></span></h6>
                    <b-table
                        ref="refUserListTable1"
                        class="position-relative"
                        :items="Composants.itemsComposant1"
                        responsive
                        :fields="fieldsComposant"
                        primary-key="id"
                        show-empty
                        empty-text="No matching records found"
                      >
                        <template #cell(releve)="data">
                          <div class="text-nowrap">
                            <b-form-checkbox value="1" checked="1" ></b-form-checkbox>
                          </div>
                        </template>
                        <!-- Column: Role -->
                        <template #cell(type)="data">
                          <div class="text-nowrap">
                            <span class="align-text-top text-capitalize text-primary">{{ data.item.type }}</span>
                          </div>
                        </template>

                        <template #cell(nature)="data">
                        <div class="text-nowrap"  style="width:400px">
                            <b-form-input type="text" :value="data.item.nature"></b-form-input>
                          </div>          
                              
                        </template>

                        <!-- Column: Role -->
                        <template #cell(iqoa)="data">
                          <div class="text-nowrap" style="width:100px">
                            <v-select
                              v-model="data.item.iqoa"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :options=iqoas
                              :clearable="false"
                              input-id="iqoa"
                            />
                          </div>
                        </template>

                        <template #cell(fiche)="data">
                        <div class="text-nowrap">
                            <span class="align-text-top text-capitalize"></span>
                          </div>          
                              
                        </template>                   

                      </b-table>
                      <hr/>
                      <h6 classs="mb-2">Elements de protection<span style="float:right"><b-link @click="onComposantNew">Ajouter un composant</b-link></span></h6>
                    <b-table
                        ref="refUserListTable2"
                        class="position-relative"
                        :items="Composants.itemsComposant2"
                        responsive
                        :fields="fieldsComposant"
                        primary-key="id"
                        show-empty
                        empty-text="No matching records found"
                      >
                        <template #cell(releve)="data">
                          <div class="text-nowrap">
                            <b-form-checkbox value="1" checked="1" ></b-form-checkbox>
                          </div>
                        </template>
                        <!-- Column: Role -->
                        <template #cell(type)="data">
                          <div class="text-nowrap">
                            <span class="align-text-top text-capitalize text-primary">{{ data.item.type }}</span>
                          </div>
                        </template>

                        <template #cell(nature)="data">
                        <div class="text-nowrap"  style="width:400px">
                            <b-form-input type="text" :value="data.item.nature"></b-form-input>
                          </div>          
                              
                        </template>

                        <!-- Column: Role -->
                        <template #cell(iqoa)="data">
                          <div class="text-nowrap" style="width:100px">
                            <v-select
                              v-model="data.item.iqoa"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :options=iqoas
                              :clearable="false"
                              input-id="iqoa"
                            />
                          </div>
                        </template>

                        <template #cell(fiche)="data">
                        <div class="text-nowrap">
                            <span class="align-text-top text-capitalize"></span>
                          </div>          
                              
                        </template>                   

                      </b-table>
                      <hr/>
                      <h6 classs="mb-2">Structure<span style="float:right"><b-link @click="onComposantNew">Ajouter un composant</b-link></span></h6>

                    <b-table
                        ref="refUserListTable3"
                        class="position-relative"
                        :items="Composants.itemsComposant3"
                        responsive
                        :fields="fieldsComposant"
                        primary-key="id"
                        show-empty
                        empty-text="No matching records found"
                      >
                        <template #cell(releve)="data">
                          <div class="text-nowrap">
                            <b-form-checkbox value="1" checked="1" ></b-form-checkbox>
                          </div>
                        </template>
                        <!-- Column: Role -->
                        <template #cell(type)="data">
                          <div class="text-nowrap">
                            <span class="align-text-top text-capitalize text-primary">{{ data.item.type }}</span>
                          </div>
                        </template>

                        <template #cell(nature)="data">
                        <div class="text-nowrap"  style="width:400px">
                            <b-form-input type="text" :value="data.item.nature"></b-form-input>
                          </div>          
                              
                        </template>

                        <!-- Column: Role -->
                        <template #cell(iqoa)="data">
                          <div class="text-nowrap" style="width:100px">
                            <v-select
                              v-model="data.item.iqoa"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :options=iqoas
                              :clearable="false"
                              input-id="iqoa"
                            />
                          </div>
                        </template>

                        <template #cell(fiche)="data">
                        <div class="text-nowrap">
                            <span class="align-text-top text-capitalize" ></span>
                          </div>          
                              
                        </template>                   

                      </b-table>                                            

                  </div>
                </div>
                <div class="fichecontent" v-if="toogle == 'actions'">
                  <b-row>
                    <b-col class="mb-2 nopad">
                      <hr/>
                      <h6>Paramètrage de chaque type d'inspection sur cet ouvrage<span style="float:right"><b-link @click="onTypeinsNew">Ajouter une configuration</b-link></span></h6>
                    <b-table
                        ref="refRoleListTable"
                        class="position-relative mb-2"
                        :items="itemsConfiguration"
                        responsive
                        :fields="fieldsConfiguration"
                        primary-key="id"
                        show-empty
                        empty-text="No matching records found"
                      > 

                        <!-- Column: User -->
                        <template #cell(typeConf)="data">
                        <div class="text-nowrap">
                            <span class="align-text-top text-capitalize text-primary">{{ data.item.typeConf }}</span>
                          </div>          
                              
                        </template>

                        <!-- Column: Role -->
                        <template #cell(periodicite)="data">
                          <div class="text-nowrap">
                            <span class="align-text-top text-capitalize">{{ data.item.periodicite }}</span>
                          </div>
                        </template>

                        <!-- Column: User -->
                        <template #cell(last)="data">
                        <div class="text-nowrap">
                            <span class="align-text-top text-capitalize">{{ data.item.last }}</span>
                          </div>          
                              
                        </template>

                        <!-- Column: Role -->
                        <template #cell(next)="data">
                          <div class="text-nowrap">
                            <span class="align-text-top text-capitalize">{{ data.item.next }}</span>
                          </div>
                        </template>

                        <!-- Column: User -->
                        <template #cell(releve)="data">
                        <div class="text-nowrap">
                            <span class="align-text-top text-capitalize">{{ data.item.releve }}</span>
                          </div>          
                              
                        </template>

                        <!-- Column: Role -->
                        <template #cell(fiche)="data">
                          <div class="text-nowrap">
                            <span class="align-text-top text-capitalize">{{ data.item.fiche }}</span>
                          </div>
                        </template>

                        <!-- Column: User -->
                        <template #cell(budget)="data">
                        <div class="text-nowrap">
                            <span class="align-text-top text-capitalize">{{ data.item.budget }}</span>
                          </div>          
                              
                        </template>

                        <!-- Column: Role -->
                        <template #cell(duree)="data">
                          <div class="text-nowrap">
                            <span class="align-text-top text-capitalize">{{ data.item.duree }}</span>
                          </div>
                        </template>                                                                        

                      </b-table>
                      <hr/>
                      <h6 >Paramètrage de la maintenance préventive<span style="float:right"><b-link @click="onMaintenanceNew">Ajouter une maintenance préventive</b-link></span></h6>

                    <b-table
                        ref="refRoleListTable"
                        class="position-relative"
                        :items="itemsMaintenance"
                        responsive
                        :fields="fieldsMaintenance"
                        primary-key="id"
                        show-empty
                        empty-text="No matching records found"
                      > 

                        <!-- Column: User -->
                        <template #cell(typeConf)="data">
                        <div class="text-nowrap">
                            <span class="align-text-top text-capitalize text-primary">{{ data.item.typeConf }}</span>
                          </div>          
                              
                        </template>

                        <!-- Column: Role -->
                        <template #cell(periodicite)="data">
                          <div class="text-nowrap">
                            <span class="align-text-top text-capitalize">{{ data.item.periodicite }}</span>
                          </div>
                        </template>

                        <!-- Column: User -->
                        <template #cell(last)="data">
                        <div class="text-nowrap">
                            <span class="align-text-top text-capitalize">{{ data.item.last }}</span>
                          </div>          
                              
                        </template>

                        <!-- Column: Role -->
                        <template #cell(next)="data">
                          <div class="text-nowrap">
                            <span class="align-text-top text-capitalize">{{ data.item.next }}</span>
                          </div>
                        </template>

                        <!-- Column: User -->
                        <template #cell(budget)="data">
                        <div class="text-nowrap">
                            <span class="align-text-top text-capitalize ">{{ data.item.budget }}</span>
                          </div>          
                              
                        </template>

                        <!-- Column: Role -->
                        <template #cell(duree)="data">
                          <div class="text-nowrap">
                            <span class="align-text-top text-capitalize">{{ data.item.duree }}</span>
                          </div>
                        </template>                                                                        

                      </b-table>

                    </b-col>
                  </b-row>                  
                </div>
                <div class="fichecontent" v-if="toogle == 'contrainte'">

                  <b-row>
                    <b-col class="mb-4 nopad">
                      <hr/>
                      <h6> Liste des contraintes et moyens nécessaires liés aux inspections sur cet ouvrage<span style="float:right"><b-link @click="onContrainteNew">Ajouter une contrainte</b-link></span></h6>

                   <b-table
                        ref="refRoleListTable"
                        class="position-relative"
                        :items="itemsContrainte"
                        responsive
                        :fields="fieldsContrainte"
                        primary-key="id"
                        show-empty
                        empty-text="No matching records found"
                      > 

                        <!-- Column: User -->
                        <template #cell(contrainte)="data">
                        <div class="text-nowrap">
                            <span class="align-text-top text-capitalize text-primary">{{ data.item.contrainte }}</span>
                          </div>          
                              
                        </template>

                        <!-- Column: Role -->
                        <template #cell(tiers)="data">
                          <div class="text-nowrap">
                            <span class="align-text-top text-capitalize">{{ data.item.tiers }}</span>
                          </div>
                        </template>

                        <!-- Column: User -->
                        <template #cell(delai)="data">
                        <div class="text-nowrap">
                            <span class="align-text-top text-capitalize">{{ data.item.delai }}</span>
                          </div>          
                              
                        </template>

                        <!-- Column: Role -->
                        <template #cell(type)="data">
                          <div class="text-nowrap">
                            <span class="align-text-top text-capitalize">{{ data.item.type }}</span>
                          </div>
                        </template>
                      </b-table>

                    </b-col>
                  </b-row>

                </div>
                <div class="fichecontent" v-if="toogle == 'documentation'">
                      <hr/>
                      <h6> Base documentaire</h6>   
                  <div>
                      <b-tabs content-class="pt-1" fill>
                        <b-tab
                          title="1. Conception et construction"
                          active
                        >
                        <b-tabs pills vertical>
                          <b-tab
                            class="btabwidth"
                            title="1.1 Etudes géologiques et géotechniques générales"
                            active
                          >
                            <b-card-text>
                              <h4>1.1 Etudes géologiques et géotechniques générales</h4>
                            </b-card-text>
                           <b-card-text>
                              Il s’agit des études effectuées pour l’étude préalable routière et reprises dans ce sous-dossier.
                            </b-card-text>
                            <div class="mt-2"><file-list /></div>
                            
                          </b-tab>
                          <b-tab title="1.2 Etudes géotechniques spécifiques à l’ouvrage">
                            <b-card-text>
                              <h4>1.2 Etudes géotechniques spécifiques à l’ouvrage</h4>
                            </b-card-text>
                           <b-card-text>
                              Ce document comprend les études initiales, ainsi que les données
                              hydrogéologiques.
                              Si ces renseignements sont complétés par des mesures effectuées
                              ultèrieurement, notamment pendant les travaux, dont les résultats
                              sont classés dans une autre pièce du dossier (pièce n° 1.7 par exemple),
                              il est souhaitable de le mentionner ici.
                            </b-card-text>
                            <div class="mt-2"><file-list /></div>
                          </b-tab>
                          <b-tab title="1.3.1 Etudes hydrauliques">
                            <b-card-text>
                              <h4>1.3.1 Etudes hydrauliques</h4>
                            </b-card-text>
                           <b-card-text>
                              Ne contient que les études particulières à l’ouvrage.
                              Pour les études générales, préciser le lieu de classement.
                            </b-card-text>
                            <div class="mt-2"><file-list /></div>
                          </b-tab>
                          <b-tab title="1.3.2 Etudes de bruit">
                            <b-card-text>
                              <h4>1.3.2 Etudes de bruit</h4>
                            </b-card-text>
                           <b-card-text>
                              Particulièrement s’il y a une incidence sur l’ouvrage (écrans antibruit par exemple).
                            </b-card-text>
                            <div class="mt-2"><file-list /></div>
                          </b-tab>
                          <b-tab title="1.3.3 Etudes de vent">
                            <b-card-text>
                              <h4>1.3.3 Etudes de vent</h4>
                            </b-card-text>
                           <b-card-text>
                              Si des études ont été menées.
                            </b-card-text>
                            <div class="mt-2"><file-list /></div>
                          </b-tab>
                          <b-tab title="1.3.4 Etudes au séisme">
                            <b-card-text>
                              <h4>1.3.4 Etudes au séisme</h4>
                            </b-card-text>
                           <b-card-text>
                              Si des études ont été menées.
                            </b-card-text>
                            <div class="mt-2"><file-list /></div>
                          </b-tab>
                          <b-tab title="1.3.5 Autres études particulières">
                            <b-card-text>
                              <h4>1.3.5 Autres études particulières</h4>
                            </b-card-text>
                           <b-card-text>
                              Il s’agit d’études spécifiques à l’ouvrage, qui n’entrent pas dans les pièces 1.3.1 à 1.3.4 ci-dessus.
                              Exemples : études de tenue au feu d’un tunnel, études de risques ou études environnementales, analyses de cycle de vie, etc.
                            </b-card-text>
                            <div class="mt-2"><file-list /></div>
                          </b-tab>
                          <b-tab title="1.4 Référence au dossier de marché">
                            <b-card-text>
                              <h4>1.4 Référence au dossier de marché</h4>
                            </b-card-text>
                           <b-card-text>
                              Copie intégrale du marché initial.
                              Plans contractuels.
                              Décompte Général et Définitif (DGD), avenants.
                              PV des opérations préalables à la réception (y compris levées de réserves).
                              PV de réception.
                            </b-card-text>
                            <div class="mt-2"><file-list /></div>
                          </b-tab>
                          <b-tab title="1.5 Notes de calcul d’exécution incluant la mise en évidence des hypothèses">
                            <b-card-text>
                              <h4>1.5 Notes de calcul d’exécution incluant la mise en évidence des hypothèses</h4>
                            </b-card-text>
                           <b-card-text>
                              Notes de calcul relatives aux différentes phases de construction.
                            </b-card-text>
                            <div class="mt-2"><file-list /></div>
                          </b-tab>
                          <b-tab title="1.6 Notes de calcul et plans relatifs aux ouvrages provisoires et procédés d’exécution">
                            <b-card-text>
                              <h4>Notes de calcul et plans relatifs aux ouvrages provisoires et procédés d’exécution</h4>
                            </b-card-text>
                           <b-card-text>
                              Ouvrages provisoires, équipements particuliers, fondations provisoires comprises.
                            </b-card-text>
                            <div class="mt-2"><file-list /></div>
                          </b-tab>    
                          <b-tab title="1.7 Parties de PAQ spécifiques de l’ouvrage et de ses remblais contigus, contrôle intérieur, contrôle extérieur">
                            <b-card-text>
                              <h4>Parties de PAQ spécifiques de l’ouvrage et de ses remblais contigus, contrôle intérieur, contrôle extérieur</h4>
                            </b-card-text>
                           <b-card-text>
                              Seules sont insérées dans le sous-dossier 1 les parties de PAQ qui ont trait strictement à la construction de l’ouvrage et de ses remblais
                              (sont exclues les généralités). Les éléments du contrôle intérieur (y compris les PV de réception des matériaux) et du contrôle extérieur 
                              sont insérés dans cette pièce. Sont insérés notamment dans cette pièce :
                              • dans le cas des tunnels, les résultats du suivi géologique éventuel ;
                              • les résultats des examens particuliers auxquels il est procédé, dans le cas où l’ouvrage est utilisé pour une circulation de
                              chantier avant les épreuves de chargement.
                            </b-card-text>
                          </b-tab>
                          <b-tab title="1.8 Correspondance relative à l’exécution des travaux">
                            <b-card-text>
                              <h4>1.8 Correspondance relative à l’exécution des travaux</h4>
                            </b-card-text>
                           <b-card-text>
                              Elle comprend les correspondances principales relatives à l’exécution 
                              des travaux, et celles relatives à la conduite des travaux qui peuvent
                              avoir une incidence sur la tenue ultèrieure de l’ouvrage (notamment toutes les mises en demeure relatives à la conduite de travaux
                              ou à la sécurisation de l’ouvrage).
                            </b-card-text>
                          </b-tab>
                          <b-tab title="1.9 Comptes-rendus des réunions de chantier, constats d’événements">
                            <b-card-text>
                              <h4>1.9 Comptes-rendus des réunions de chantier, constats d’événements</h4>
                            </b-card-text>
                           <b-card-text>
                              Si l’ouvrage est réalisé dans un marché comprenant plusieurs ouvrages,
                              préciser les noms des autres ouvrages concernés et les lieux où ces compte-rendus peuvent être consultés, s’ils ne sont
                              pas intégrés in extenso dans cette pièce.
                            </b-card-text>
                          </b-tab>
                          <b-tab title="1.10 Journal de chantier">
                            <b-card-text>
                              <h4>1.10 Journal de chantier</h4>
                            </b-card-text>
                           <b-card-text>
                              Journal tenu au jour le jour par le contrôleur (document original).
                            </b-card-text>
                          </b-tab>    
                          <b-tab title="1.11 Documents spécifiques">
                            <b-card-text>
                              <h4>1.11 Documents spécifiques</h4>
                            </b-card-text>
                           <b-card-text>
                              Méthodes particulières d’exécution, matériaux nouveaux : ils sont
                              décrits avec précision ainsi que les consignes et précautions d’utilisation
                              (par exemple, procédés particuliers de mise en oeuvre, déplacements
                              d’équipages mobiles, tunneliers, etc.).
                            </b-card-text>
                          </b-tab>    
                          <b-tab title="1.12 Plans certifiés conformes à l’exécution et dossier photos en cours de travaux">
                            <b-card-text>
                              <h4>1.12 Plans certifiés conformes à l’exécution et dossier photos en cours de travaux</h4>
                            </b-card-text>
                           <b-card-text>
                            </b-card-text>
                          </b-tab>
                          <b-tab title="1.12.1 Plans certifiés conformes à l’exécution">
                            <b-card-text>
                              <h4>1.12.1 Plans certifiés conformes à l’exécution</h4>
                            </b-card-text>
                           <b-card-text>
                              Plans portant la mention " certifié conforme à l’exécution " (plans de récolement)
                            </b-card-text>
                          </b-tab>
                          <b-tab title="1.12.2 Dossier photos en cours de travaux">
                            <b-card-text>
                              <h4>1.12.2 Dossier photos en cours de travaux</h4>
                            </b-card-text>
                           <b-card-text>
                              Un dossier photographique en cours de travaux complète utilement ces plans.
                            </b-card-text>
                          </b-tab>
                          <b-tab title="1.13 Epreuves">
                            <b-card-text>
                              <h4>1.13 Epreuves</h4>
                            </b-card-text>
                           <b-card-text>
                              Comprenant le PV des épreuves, le programme de chargement, les résultats obtenus et l’interprétation des résultats.
                            </b-card-text>
                          </b-tab>   
                          <b-tab title="1.14 Opérations entrainant une modification de l’état de référence">
                            <b-card-text>
                              <h4>1.14 Opérations entrainant une modification de l’état de référence</h4>
                            </b-card-text>
                           <b-card-text>
                            </b-card-text>
                          </b-tab>    
                          <b-tab title="1.14.1 Origine des constatations ayant conduit à la décision d’effectuer une grosse opération">
                            <b-card-text>
                              <h4>1.14.1 Origine des constatations ayant conduit à la décision d’effectuer une grosse opération</h4>
                            </b-card-text>
                           <b-card-text>
                              Origine des constatations ayant conduit à la décision d’effectuer
                              une grosse opération de renforcement ou de réparation (un renvoi
                              au procès-verbal de visite ou d’inspection peut suffire).
                              ou
                              Référence à la décision de principe de modifier la géométrie ou la capacité portante de l’ouvrage.
                            </b-card-text>
                          </b-tab>    
                          <b-tab title="1.14.2 Rapport de l’inspection détaillée particulière préalable à la réparation">
                            <b-card-text>
                              <h4>1.14.2 Rapport de l’inspection détaillée particulière préalable à la réparation</h4>
                            </b-card-text>
                           <b-card-text>
                              Rapport de l’inspection détaillée particulière préalable à la réparation ou à la modification.
                            </b-card-text>
                          </b-tab>
                          <b-tab title="1.14.3 Résultats de toutes les investigations tant de laboratoires que de bureaux d’études">
                            <b-card-text>
                              <h4>1.14.3 Résultats de toutes les investigations tant de laboratoires que de bureaux d’études</h4>
                            </b-card-text>
                           <b-card-text>
                              Résultats de toutes les investigations tant de laboratoires que de bureaux d’études, effectuées avant la mise au point du projet.
                            </b-card-text>
                          </b-tab>
                          <b-tab title="1.14.4 Historisation ancien documents">
                             <b-card-text>
                              <h4>1.14.4 Historisation ancien documents</h4>
                            </b-card-text>
                           <b-card-text>
                              Documents relatifs à la consultation des entreprises et au marché, aux études postérieures à la dévolution des travaux, au déroulement
                              des travaux. Documents composés des mêmes éléments que les pièces 1.4 à 1.13.
                            </b-card-text>
                          </b-tab>
                          <b-tab title="1.14.14 Pièces des anciens sous-dossiers 2 et 3 devenues caduques.">
                            <b-card-text>
                              <h4>1.14.14 Pièces des anciens sous-dossiers 2 et 3 devenues caduques.</h4>
                            </b-card-text>
                           <b-card-text>
                              Pièces des anciens sous-dossiers 2 et 3 devenues caduques.
                            </b-card-text>
                          </b-tab>   
                          <b-tab title="1.15 Notes de calcul postèrieures à la construction de l’ouvrage">
                            <b-card-text>
                              <h4>1.15 Notes de calcul postèrieures à la construction de l’ouvrage</h4>
                            </b-card-text>
                           <b-card-text>
                              Par exemple : notes de calcul pour le passage de convois exceptionnels.
                            </b-card-text>
                          </b-tab>                                                                                                                                                                                                            
                        </b-tabs>
                        </b-tab>
                        <b-tab
                          title="2. Eléments de référence pour la gestion"
                        >
                          <b-tabs pills vertical>
                            <b-tab
                              title="2.1 Dossier photographique"
                              active
                            >
                              <b-card-text>
                                <h4>2.1 Dossier photographique</h4>
                              </b-card-text>
                            <b-card-text>
                                Dans le cas d’un ouvrage neuf, ce dossier comprend au moins
                                des vues d’ensemble de l’ouvrage terminé. Dans le cas d’un ouvrage existant, ce dossier peut être complété
                                par les autres photographies utiles à la gestion de l’ouvrage (abords, ouvrage vu de dessous, désordres antérieurs, etc.).
                                En cas de modification de l’état de référence, ce dossier est à compléter. Les pièces devenues caduques sont à placer dans le document 1.14.14.
                              </b-card-text>
                              <div class="mt-2"><file-list /></div>
                            </b-tab>
                            <b-tab title="2.2 Etat de référence">
                              <b-card-text>
                                <h4>2.2 Etat de référence</h4>
                              </b-card-text>
                            <b-card-text>
                                • procès-verbal de l’inspection détaillée initiale ;
                                • relevé topométrique de référence (point zéro des mesures de nivellement et de convergence) ;
                                • mesures de référence sur les instruments de mesure installés (s’il y a lieu).
                                En cas de modification de l’état de référence :
                                • procès-verbal de la nouvelle inspection détaillée initiale ;
                                • nouveau relevé topométrique de référence, s’il est jugé nécessaire par le niveau organisationnel ;
                                • nouvelles mesures de référence sur les instruments de mesure installés (s’il y a lieu).
                                Les pièces devenues caduques sont à placer dans le document 1.14.14.
                              </b-card-text>
                              <div class="mt-2"><file-list /></div>
                            </b-tab>
                            <b-tab
                              title="2.3 Document signalétique"
                            >
                              <b-card-text>
                                <h4>2.3 Document signalétique</h4>
                              </b-card-text>
                            <b-card-text>
                                CETTE PIECE EST FONDAMENTALE
                                Le document comprend :
                                • 2.3.a) Fiche signalétique ;
                                • 2.3.b) Plans descriptifs ;
                                • 2.3.c) Particularités de l’ouvrage.
                                Le contenu de ces pièces est présenté dans des annexes distinctes,
                                selon qu’il s’agit d’ouvrages de franchissement (annexes 1 et 4),
                                de soutènements (annexes 1 et 5), du génie civil des tunnels
                                (annexes 2 et 6) ou des équipements des tunnels (annexe 3).
                                En cas de modification de l’état de référence, ce dossier est à actualiser.
                                Les pièces devenues caduques sont à placer dans le document 1.14.14.
                              </b-card-text>
                              <div class="mt-2"><file-list /></div>
                            </b-tab>
                            <b-tab title="2.4 Document de synthèse">
                              <b-card-text>
                                <h4>2.4 Document de synthèse</h4>
                              </b-card-text>
                            <b-card-text>
                                CETTE PIECE EST FONDAMENTALE
                                Le document de synthèse est à établir par le maître d’oeuvre,
                                sous la responsabilité du maître d’ouvrage constructeur et en liaison
                                étroite avec le maître d’ouvrage gestionnaire, pour tout ce qui concerne
                                la gestion, la surveillance et l’entretien ultérieur (cf. chapitre 2
                                paragraphe 3.1 du présent fascicule).
                                L’en-tête du document comprend les renseignements suivants :
                                désignation de l’opération, désignation de l’ouvrage, maître d’oeuvre
                                constructeur, date d’établissement du document de synthèse.
                                Ce document doit comporter les indications suivantes :
                                • 2.4.a) Points faibles éventuels, adaptations réalisées en cours de
                                chantier ;
                                • 2.4.b) Prévisions d’évolution de l’ouvrage ;
                                • 2.4.c) Conditions de visite (ouvrages de franchissement)
                                ou tronçonnage de l’ouvrage (tunnels) ;
                                • 2.4.d) Opérations spécifiques de surveillance à prévoir ;
                                • 2.4.e) Conditions d’entretien spécialisé ;
                                • 2.4.f) Dispositions prévues pour permettre le renforcement,
                                l’étaiement provisoire, le soulèvement ;
                                • 2.4.g) Indications concernant le classement ou la protection
                                du site ou de l’ouvrage ;
                                • 2.4.h) échéancier d’expiration de toutes les garanties attachées
                                à l’ouvrage.
                                Le contenu de ces pièces est présenté dans des annexes distinctes,
                                selon qu’il s’agisse d’ouvrages de franchissement (annexe 4),
                                d’ouvrages de soutènements (annexe 5) du génie civil des tunnels
                                (annexe 6) ou des équipements des tunnels (annexe 3).
                                En cas de modification de l’état de référence, ce dossier est à actualiser.
                              </b-card-text>
                              <div class="mt-2"><file-list /></div>
                            </b-tab>       
<b-tab
                              title="2.5 Notice de visite et d’entretien"
                            >
                              <b-card-text>
                                <h4>2.5 Notice de visite et d’entretien</h4>
                              </b-card-text>
                            <b-card-text>
                              Il s’agit en fait du " mode d’emploi " complet de l’ouvrage.
                              En particulier, il contient l’ensemble des détails de l’assainissement
                              et du drainage pour en faciliter l’entretien.
                              Les conditions de visite des ouvrages de franchissement sont quant
                              à elles décrites dans la pièce 2.4.c) du document de synthèse
                              correspondant.
                              Le DIUO (pièce n° 2.6) insiste lui sur la sécurité des personnes lors
                              des visites et des opérations de maintenance.
                              En cas de modification de l’état de référence, ce dossier est à actualiser.
                              Les pièces devenues caduques sont à placer dans le document 1.14.14.
                              </b-card-text>
                              <div class="mt-2"><file-list /></div>
                            </b-tab>
                            <b-tab title="2.6 Dossier d’intervention ultèrieure sur l’ouvrage(DIUO)">
                              <b-card-text>
                                <h4>2.6 Dossier d’intervention ultèrieure sur l’ouvrage(DIUO)</h4>
                              </b-card-text>
                            <b-card-text>
                                Le dossier est établi par le Coordonnateur SPS (CSPS). Il rassemble
                                toutes les données de nature à faciliter la prévention des risques
                                professionnels lors d’interventions ultèrieures sur l’ouvrage, telles que
                                les visites et les opérations d’entretien.
                                Ce doit être un document opérationnel adapté aux besoins du maître
                                d’ouvrage gestionnaire, qui devrait comporter :
                                • une présentation générale de l’ouvrage et des conditions de
                                sa réalisation ;
                                • les fiches relatives aux interventions ultérieures, avec
                                les risques liés aux accès et aux interventions elles-mêmes
                                (par intervention, par lieu d’intervention, par corps d’état) ;
                                • les documents annexés (éléments du dossier des ouvrages exécutés
                                [DOE], plan d’accès et de circulation, notes de calcul, plans et
                                schémas utiles à la réalisation de l’intervention, dossier technique
                                regroupant les informations relatives à l’identification
                                des matériaux, etc.).
                                En cas de modification de l’état de référence faisant intervenir un CSPS,
                                cette pièce est à établir si elle est manquante, ou bien à modifier.
                                Les pièces devenues caduques sont à placer dans le document 1.14.14.
                              </b-card-text>
                            </b-tab>        
                            <b-tab
                              title="2.7.1 Convention de gestion de l’ouvrage"
                            >
                              <b-card-text>
                                <h4>2.7.1 Convention de gestion de l’ouvrage</h4>
                              </b-card-text>
                            <b-card-text>
                              Il s’agit de la convention régissant les conditions techniques
                              et financières de la gestion de l’ouvrage, dans le cas d’un partage des responsabilités.
                              </b-card-text>
                            </b-tab>
                            <b-tab title="2.7.2 Règlements et conventions relatifs aux réseaux">
                              <b-card-text>
                                <h4>2.7.2 Règlements et conventions relatifs aux réseaux</h4>
                              </b-card-text>
                            <b-card-text>
                              Il s’agit des textes qui régissent les rapports entre le maître d’ouvrage
                              gestionnaire et les gestionnaires des réseaux, transitant sur ou dans l’ouvrage.
                              </b-card-text>
                            </b-tab>  
                            <b-tab
                              title="2.8 Liste récapitulative des intervenants"
                            >
                              <b-card-text>
                                <h4>2.8 Liste récapitulative des noms des personnes susceptibles d’intervenir en cas de besoin</h4>
                              </b-card-text>
                            <b-card-text>
                              Cette liste détaille :
                              • les noms des maîtres d’ouvrages gestionnaires et des concessionnaires
                              de réseaux ;
                              • les noms des personnes susceptibles d’intervenir sur ou dans l’ouvrage,
                              avec leurs coordonnées (téléphoniques, de messagerie) et leur
                              fonction.
                              </b-card-text>
                            </b-tab>
                            <b-tab title="2.9 Procès-verbal de remise">
                              <b-card-text>
                                <h4>2.9 Procès-verbal de remise au maître d’ouvrage gestionnaire</h4>
                              </b-card-text>
                            <b-card-text>
                              Les conditions d’établissement de cette pièce sont précisées
                              </b-card-text>
                            </b-tab>                                                                                                    
                          </b-tabs>
                        </b-tab>
                        <b-tab title="3. Vie de l'ouvrage">
                            <b-tabs pills vertical>
                            <b-tab
                              title="3.1 Calendrier des opérations de surveillance"
                              active
                            >
                              <b-card-text>
                                <h4>3.1 Calendrier des opérations de surveillance</h4>
                              </b-card-text>
                            <b-card-text>
                              Ce document précise notamment le calendrier des visites et inspections
                              prévues et effectuées, détaillées dans la pièce n° 3.2 ci-après,
                              à l’exception des dates du contrôle annuel.
                              Dans le cas où l’ouvrage est placé sous surveillance renforcée
                              (cf. fascicule 3 de l’ITSEOA), le calendrier doit être précisé dans
                              ce document.
                              </b-card-text>
                              <div class="mt-2"><file-list /></div>
                            </b-tab>
                            <b-tab title="3.2 Dossier de surveillance">
                              <b-card-text>
                                <h4>3.2 Dossier de surveillance</h4>
                              </b-card-text>
                            <b-card-text>
                              Il s’agit du recueil des documents suivants :
                              • procès-verbaux de contrôle annuel ;
                              • procès-verbaux de visite d’évaluation ;
                              • rapports d’inspection détaillée ;
                              • rapports d’inspection détaillée de parties d’ouvrage (inspection
                              détaillée spécifique, comme celle des fondations aquatiques
                              par exemple) ;
                              • rapports d’inspection spécifique de fins de garanties contractuelles ;
                              • procès-verbaux de visite exceptionnelle et/ou rapports d’inspection
                              détaillée exceptionnelle, liée à des événements imprévus.
                              Dans le cas où des opérations spécifiques de surveillance sont prévues,
                              préciser la nature, l’emplacement et le fonctionnement des appareils
                              de mesure installés à demeure, ainsi que la fréquence des mesures
                              à réaliser et les points zéro.
                              </b-card-text>
                              <div class="mt-2"><file-list /></div>
                            </b-tab>
                            <b-tab
                              title="3.3 Liste et calendrier des opérations systématiques d’entretien spécialisé"
                            >
                              <b-card-text>
                                <h4>3.3 Liste et calendrier des opérations systématiques d’entretien spécialisé</h4>
                              </b-card-text>
                            <b-card-text>
                                  Il s’agit des opérations systématiques d’entretien spécialisé prévues
                                  dès la construction de l’ouvrage, dont la programmation pluriannuelle
                                  ou la fréquence est précisée dans cette pièce du dossier d’ouvrage.
                              </b-card-text>
                              <div class="mt-2"><file-list /></div>
                            </b-tab>
                            <b-tab
                              title="3.4 Liste des diverses garanties, avec dates d’expiration"
                            >
                              <b-card-text>
                                <h4>3.4 Liste des diverses garanties, avec dates d’expiration</h4>
                              </b-card-text>
                            <b-card-text>
                              Ce document doit être mis à jour dans le cas où des travaux faisant
                              l’objet d’une garantie sont exécutés sur l’ouvrage (y compris
                              le remplacement de certains équipements).
                              Sont exclus de ce document les équipements d’exploitation
                              et de sécurité entrant dans la composition du " dossier
                              des équipements des tunnels " 
                              </b-card-text>
                              <div class="mt-2"><file-list /></div>
                            </b-tab>
                            <b-tab title="3.5 Dossier des opérations ne justifiant pas un nouvel état de référence">
                              <b-card-text>
                                <h4>3.5 Dossier des opérations ne justifiant pas un nouvel état de référence</h4>
                              </b-card-text>
                            <b-card-text>
                              Il s’agit des opérations d’entretien spécialisé, de réparation
                              ou de modification, qui ne justifient pas la définition d’un nouvel état
                              de référence. Ce dossier doit synthétiser les éléments essentiels
                              des opérations.
                              </b-card-text>
                            </b-tab>
                            <b-tab
                              title="3.6 Evénements importants et actions exceptionnelles subis par l’ouvrage"
                            >
                              <b-card-text>
                                <h4>3.6 Evénements importants et actions exceptionnelles subis par l’ouvrage</h4>
                              </b-card-text>
                            <b-card-text>
                                  Il s’agit de recenser des phénomènes naturels (crues, séismes,
                                  glissements de terrain, embâcles, etc.), ainsi que des actions dues
                                  à l’homme (incendie, chocs, passages de transports exceptionnels
                                  non pris en compte dans le dimensionnement de l’ouvrage, etc.),
                                  en indiquant leurs caractéristiques principales.
                              </b-card-text>
                            </b-tab>  
                            <b-tab title="3.7 Dossier de surveillance renforcée, de haute surveillance et de diagnostic">
                              <b-card-text>
                                <h4>3.7 Dossier de surveillance renforcée, de haute surveillance et de diagnostic (Cf. fascicule 3 de l’ITSEOA)</h4>
                              </b-card-text>
                            <b-card-text>
                              Ce dossier comprend l’ensemble des documents relatifs :
                              • à la mise sous surveillance renforcée ;
                              • à la mise sous haute surveillance ;
                              • aux études de diagnostic de l’ouvrage.
                              </b-card-text>
                            </b-tab>
                            <b-tab
                              title="3.8 Chronologie des événements importants de la vie de l’ouvrage"
                            >
                              <b-card-text>
                                <h4>3.8 Chronologie des événements importants de la vie de l’ouvrage</h4>
                              </b-card-text>
                            <b-card-text>
                              Pour un ouvrage ayant subi diverses réparations par exemple, ce dossier
                              contient les pièces permettant d’expliquer les diverses transformations
                              opérées sur l’ouvrage.
                                                            </b-card-text>
                            </b-tab>                                                        
                            </b-tabs>
                        </b-tab>
                      </b-tabs>
                  </div>
                </div>                
                <div class="fichecontent" v-if="toogle == 'galerie'">
                      <hr/>
                      <h6> Liste des photos <span style="float:right"><b-link @click="onCaracNew">Ajouter une photo</b-link></span></h6>   
                  <div>
                    <PatrimoineGalerie/>
                  </div>
                </div>
                <div class="fichecontent" v-if="toogle == 'vue'">
                      <hr/>
                      <h6> Liste des coupes / Vues <span style="float:right"><b-link @click="onCaracNew">Ajouter une vue</b-link></span></h6>                    
                    <div>
                        <swiper
                        class="swiper-navigations"
                        :options="swiperOptions"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      >
                        <swiper-slide
                          v-for="(data,index) in swiperData"
                          :key="index"
                        >
                          <b-img
                            :src="data.img"
                            fluid
                          />
                        </swiper-slide>

                        <!-- Add Arrows -->
                        <div
                          slot="button-next"
                          class="swiper-button-next"
                        />
                        <div
                          slot="button-prev"
                          class="swiper-button-prev"
                        />
                        <div
                          slot="pagination"
                          class="swiper-pagination"
                        />
                      </swiper>
                    </div>
                </div>
                <div class="fichecontent" v-if="toogle == 'intervenant'">
                  <div>
                      <hr/>
                      <h6> Liste des intervenants <span style="float:right"><b-link @click="onIntervenantNew">Ajouter un intervenant</b-link></span></h6>  
                    <b-table
                        ref="refRoleListTable"
                        class="position-relative"
                        :items="itemsRole"
                        responsive
                        :fields="fieldsRole"
                        primary-key="id"
                        show-empty
                        empty-text="No matching records found"
                      >

                        <!-- Column: User -->
                        <template #cell(role)="data">
                        <div class="text-nowrap">
                            <span class="align-text-top text-capitalize text-primary">{{ data.item.role }}</span>
                          </div>          
                              
                        </template>

                        <!-- Column: Role -->
                        <template #cell(who)="data">
                          <div class="text-nowrap">
                            <span class="align-text-top text-capitalize">{{ data.item.who }}</span>
                          </div>
                        </template>

                      </b-table>


 
                  </div>                  
                </div>
                <div class="fichecontent" v-if="toogle == 'document'">
                      <b-row >
                        <b-col cols="12"  md="3">
                          <b-row>
                            <b-col cols="12">
                              Parcourir
                              <!-- Searchbar -->
                              <b-input-group class="input-group-merge mb-3 w-100 round">           
                                <b-form-input
                                  v-model="searchQuery"
                                  placeholder="Filtrer"
                                />
                              </b-input-group>
                            </b-col>  
                          <!-- Filters' Card -->
                          </b-row>
                          <b-row>
                            <b-col cols="12">
                          <b-card>
                          <v-jstree :data="data" multiple allow-batch whole-row @item-click="itemClick"></v-jstree>

                          </b-card>  
                            </b-col>        
                          </b-row>
                        </b-col>
                        <b-col cols="12"  md="9">
                          <div class="viewer" style="height:800px;width:100%">
                                 <fileViewer :document="document" />
                          </div>
                        </b-col>
                      </b-row>  
                </div>
            </div>
          </b-col>
        </b-row>
      </b-card-body>         
    </b-card>
  </section>
</template>


<script>
import { ref } from '@vue/composition-api'
import router from '@/router'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import chartedit from '@/components/ChartEdit.vue'
import fileViewer from '@/components/FileViewer.vue' 
import VueApexCharts from 'vue-apexcharts'

import 'swiper/css/swiper.css'
import {
  BCard, BCardBody, BRow, BFormRadio, BCol, BImg, BCardText, BLink,BButtonToolbar, BButtonGroup, BButton, BDropdown, BDropdownItem, BAlert,BTable,BFormInput,BFormCheckbox,BCardHeader, BCardTitle, BTab, BTabs,BFormTextarea
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import PatrimoineGalerie from '@/views/apps/patrimoine/patrimoine-fiche/PatrimoineGalerie.vue'

import VJstree from 'vue-jstree'
import vSelect from 'vue-select'
import MaintenanceAddNew from '@/views/apps/patrimoine/patrimoine-fiche/MaintenanceAddNew.vue'
import TypeInspectionAddNew from '@/views/apps/patrimoine/patrimoine-fiche/TypeInspectionAddNew.vue'
import CaracAddNew from '@/views/apps/patrimoine/patrimoine-fiche/CaracAddNew.vue'
import ContrainteAddNew from '@/views/apps/patrimoine/patrimoine-fiche/ContrainteAddNew.vue'
import IntervenantAddNew from '@/views/apps/patrimoine/patrimoine-fiche/IntervenantAddNew.vue'
import ComposantAddNew from '@/views/apps/patrimoine/patrimoine-fiche/ComposantAddNew.vue'
import FicheIqoa from '@/components/FicheComposant.vue'
import FileList from '@/components/FileList.vue'
import Composants from '@/@fake-db/data/apps/composant.js'
import { $themeColors } from '@themeConfig'
import Assettypes from '@/@fake-db/data/apps/assettype.js'
import relevereadmodal from '@/components/ReleveReadModal.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BCard,
    BCardBody,
    VueApexCharts,  
    BCardHeader,
    BFormRadio,
    BFormTextarea,
    BCardTitle,
    BRow,
    BTab, BTabs,
    BCol,
    BImg,
    BCardText,
    BTable,
    BLink,
    BButtonToolbar,
    router,
    BButtonGroup,    
    BButton,
    BDropdown,
    BDropdownItem,
    BAlert,
    Swiper,
    vSelect,
    relevereadmodal,
    SwiperSlide,
    // SFC
    PatrimoineGalerie,    
    VJstree,
    chartedit,
    fileViewer,
    BFormInput,
    MaintenanceAddNew,
    TypeInspectionAddNew,
    CaracAddNew,
    BFormCheckbox,
    ContrainteAddNew,
    IntervenantAddNew,
    ComposantAddNew,
    FicheIqoa,
    FileList,
  },

  mounted() {
     // data

    this.$http.get('/apps/patrimoine/asset/'+router.currentRoute.params.id)
      .then(response => { 
        this.product = response.data 
        if (!this.product.slug) {
              this.Composants.itemsComposant = [
                  { type:"Chaussée de la voie portée",nature: '',iqoa:"1",fiche: '1',releve:"releve",releve2:"1", sortable: true },
                  { type:"Trottoirs, bordures et accotements",nature: '',iqoa:"1",fiche: '',releve:"releve", sortable: true },
                  { type:"Dispositifs de retenue",nature: '',iqoa:"1",fiche: '2',releve:"releve", sortable: true },
                  { type:"Corniches",nature: '',iqoa:"1",fiche: '',releve:"releve", sortable: true },
                  { type:"Dispositifs d'évacuation des eaux",nature: '',iqoa:"1",fiche: '',releve:"releve", sortable: true },
                  { type:"Dispositifs de retenue",nature: '',iqoa:"1",fiche: '',releve:"releve", sortable: true },
                  { type:"Autres équipements sur ouvrage",nature: '',iqoa:"1",fiche: '',releve:"releve", sortable: true },    
                ]       

              this.Composants.itemsComposant1 = [
                  { type:"Chaussée de la voie franchie",nature: '',iqoa:"1",fiche: '',releve:"releve", sortable: true },
                  { type:"Trottoirs et bordures",nature: '',iqoa:"1",fiche: '',releve:"releve", sortable: true },
                  { type:"Dispositifs de retenue",nature: '',iqoa:"1",fiche: '',releve:"releve", sortable: true },
                  { type:"Dispositifs d'évacuation des eaux",nature: '',iqoa:"1",fiche: '',releve:"releve", sortable: true },
              ]

              this.Composants.itemsComposant2 = [
                  { type:"Etanchéité",nature: '',iqoa:"1",fiche: '',releve:"releve", sortable: true },
                  { type:"Perrés",nature: '',iqoa:"1",fiche: '',releve:"releve", sortable: true },
                  { type:"Eléments de protection en site aquatique",nature: '',iqoa:"1",fiche: '',releve:"releve", sortable: true },
                  { type:"Dispositifs latéraux d'évacuation des eaux",nature: '',iqoa:"1",fiche: '',releve:"releve", sortable: true },
              ]

              this.Composants.itemsComposant3 = [
                  { type:"Tympans et murs en retour",nature: '',iqoa:"1",fiche: '',releve:"releve", sortable: true },
                  { type:"Tympans et murs en aile",nature: '',iqoa:"1",fiche: '',releve:"releve", sortable: true },
                  { type:"Voûtes et piédroits des culées ou piles",nature: '',iqoa:"1",fiche: '',releve:"releve", sortable: true },
                  { type:"Fondations Lit du cours d'eau",nature: '',iqoa:"1",fiche: '',releve:"releve", sortable: true },
                  { type:"Eléments de renforcement ou de réparation antérieurs",nature: '',iqoa:"1",fiche: '',releve:"releve", sortable: true },      
              ]
        }         
        this.product.typeName = this.Assettypes[this.product.type].name 
        this.$http.get('/apps/patrimoine/asset/'+this.product.parentid)
          .then(response => {
            this.plateforme = response.data.name
          })
          .catch((e)=>{console.log(e)})   
        })
      .catch((e)=>{console.log(e)})  
      // data  
  },

  
  data() {
    return {
              document:{},
              inMaintenanceData:{},
              inTypeinsData:{},
              inCaracData:{},
              inContrainteData:{},
              inIntervenantData:{},
              inComposantData:{},
              ficheId:'',
              plateforme:'',
      product : {
        id: '',
        type: '',
        typeName:'',
        plateforme:'',
        name: '',
        parentid: 1,
        uoid:1,
        center: [],
        zoomlevel:14,  
      },
            iqoas:['1','2','2E','3','3U'],
      toogle : "fiche",
      /* eslint-disable global-require */
      swiperData: [
        { img: require('@/assets/images/pages/patrimoine/vue1.jpeg') },
        { img: require('@/assets/images/pages/patrimoine/vue2.jpeg') },
        { img: require('@/assets/images/pages/patrimoine/vue3.jpeg') },
      ],
      /* eslint-disable global-require */
      fieldsContrainte: [
        { key: "contrainte", label: "Contrainte / Moyen", type: "text", thStyle: { width: "40%", height: "40px" }},
        { key: "tiers", label: "Tiers", type: "select", options: ['SNCF', 'SANEF', 'DREAL']},
        { key: "delai", label: "Délai(jours)", type: "text", thStyle: { width: "16%" }},
        { key: "type", label: "Type d'inspection", type: "text"},
        { key: "edit", label: "", type: "edit", thStyle: { width: "12%" }},
      ],
       itemsContrainte: [
           { contrainte:"Balisage de la route",tiers:"DREAL",delai:"60",type:"VE,CA,ID"},
           { contrainte:"Arrêté préférectoral",tiers:"",delai:"45",type:"VE,ID"},
           { contrainte:"Permis T",tiers:"",delai:"",type:"VE"},
           { contrainte:"Information",tiers:"SNCF",delai:"90",type:"CA"},
        ],  
      fieldsConfiguration: [
        { key: "typeConf", label: "Type", thStyle: { width: "40%" }},
        { key: "periodicite", label: "Périodicité"},
        { key: "last", label: "Dernière"},
        { key: "next", label: "Prochaine"},
        { key: "budget", label: "Budget(euros est.)"},
        { key: "duree", label: "Durée(jours est.)"},                
      ],
       itemsConfiguration: [
           { typeConf:"VE",periodicite:"IQOA",last:"20/12/2015",next:"20/12/2024",releve:"Relevé 1",fiche:"Standard",budget:"15 000",duree:"10"},
           { typeConf:"CA",periodicite:"IQOA",last:"12/01/2019",next:"12/01/2022",releve:"",fiche:"Fiche AGT",budget:"30 000",duree:"15"},
           { typeConf:"ID",periodicite:"2 ans",last:"06/06/2020",next:"06/06/2022",releve:"Relevé 1",fiche:"Fiche AGF",budget:"60 000",duree:"25"},
        ],   

      fieldsMaintenance: [
        { key: "typeConf", label: "Type", type: "text", thStyle: { width: "40%" }},
        { key: "periodicite", label: "Périodicité", type: "select", options: ['IQOA', '1 an', '2 ans', '3 ans', '4 ans', '5 ans', '6 ans']  },
        { key: "last", label: "Dernière", type: "text"},
        { key: "next", label: "Prochaine", type: "text"},
        { key: "budget", label: "Budget(euros est.)", type: "text" },
        { key: "duree", label: "Durée(jours est.)", type: "text"},                
      ],
       itemsMaintenance: [
           { typeConf:"Remplacement joint",periodicite:"2 ans",last:"20/12/2020",next:"20/12/2022",budget:"30 000",duree:"15"},
           { typeConf:"Etanchéité",periodicite:"4 ans",last:"12/01/2019",next:"12/01/2023",budget:"15 000",duree:"10"},
        ],  

        // Table Handlers
        fieldsCarac : [
          { key: 'carac',label:"Caractéristique", sortable: true },
          { key: 'valeur',label:"Valeur", sortable: true },
        ],

        // Table Handlers
        fieldsComposant : [
          { key: 'releve',label:"", thStyle: { height: "30px", width:"3%" } },
          { key: 'type',label:"Type", sortable: true, thStyle: { height: "30px" , width:"30%" } },
          { key: 'nature',label:"Nature", sortable: true , thStyle: { height: "30px", width:"40%" }},
          { key: 'iqoa',label:"IQOA", sortable: true , thStyle: { height: "30px", width:"50px;" }},
          { key: 'fiche',label:"Fiche", sortable: true , thStyle: { height: "30px" }},         
          { key: 'releve2',label:"", thStyle: { height: "30px", width:"3%" } },   
        ] ,  



      // Table Handlers
      itemsCarac : [
        { carac: 'Commune',valeur:"", sortable: true },
        { carac: 'Voie concernée',valeur:"", sortable: true },
        { carac: 'Voie(s) secondaires',valeur:"", sortable: true },
        { carac: 'Franchissement',valeur:"", sortable: true },
        { carac: 'Numérotation de l\'ouvrage',valeur:"", sortable: true },
        { carac: 'Origine voie concernée',valeur:"", sortable: true },
        { carac: 'Extrémité voie concernée',valeur:"", sortable: true },
        { carac: 'Orientation voie concernée',valeur:"", sortable: true },
        { carac: 'PR de l’ouvrage',valeur:"", sortable: true },
        { carac: 'Dénomination de l\'ouvrage',valeur:"", sortable: true },
        { carac: 'Autre codification de l’ouvrage',valeur:"", sortable: true },
        { carac: 'Autre dénomination de l’ouvrage',valeur:"", sortable: true },


        { carac: 'Type de structure',valeur:"", sortable: true },
        { carac: 'Nature des matériaux',valeur:"", sortable: true },
        { carac: 'Nombre de tabliers',valeur:"", sortable: true },
        { carac: 'Nombre de travées(ou arches)',valeur:"", sortable: true },
        { carac: 'Portées et ouverture',valeur:"", sortable: true },
        { carac: 'Hauteur(s) libre(s)',valeur:"", sortable: true },
        { carac: 'Longueur(s)',valeur:"", sortable: true },
        { carac: 'Largeur(s)',valeur:"", sortable: true },
        { carac: 'Lignes d\'appui(nombre, type d\'appareils d\'appui)',valeur:"", sortable: true },
        { carac: 'Particularités géométriques(biais, courbure...)',valeur:"", sortable: true },

        { carac: 'Entreprise constructrice',valeur:"", sortable: true },
        { carac: 'Sous traitant(s) gros oeuvre',valeur:"", sortable: true },
        { carac: 'Sous traitant(s) équipements',valeur:"", sortable: true },  
        { carac: 'Date (ou époque) de construction',valeur:"", sortable: true },      
        { carac: 'Mode de construction',valeur:"", sortable: true },
        { carac: 'Règlement de charges',valeur:"", sortable: true },
        { carac: 'Prise en compte de charges exceptionnelles',valeur:"", sortable: true },  
        { carac: 'Date (ou époque) de mise en service',valeur:"", sortable: true },  
        { carac: 'Particularités',valeur:"", sortable: true },                    
      ],

      // Table Handlers
      itemsRole : [
        { role: 'MOA',who:"ADP / Laboratoire", sortable: true },
        { role: 'Mainteneur',who:"ADP / CDG-R", sortable: true },
        { role: 'Tiers partie',who:"SNCF", sortable: true },
        { role: 'Tiers partie',who:"SANEF", sortable: true },                 
      ],  

      // Table Handlers
      fieldsRole : [
        { key: 'role',label:"Role", sortable: true },
        { key: 'who',label:"Intervenant", sortable: true },
      ] , 

         chartOptions: {
            legend: {
              show: false
            },
            chart: {
              height: 350,
              type: 'treemap'
            },
            title: {
              text: 'Vétusté par composant'
            },
            dataLabels: {
              enabled: true,
              style: {
                fontSize: '12px',
              },
              formatter: function(text, op) {
                return [text, op.value]
              },
              offsetY: -4
            },
            plotOptions: {
              treemap: {
                enableShades: true,
                shadeIntensity: 0.5,
                reverseNegativeShade: true,
                //distributed:true,
                colorScale: {
                  ranges: [
                    {
                      from: 0,
                      to: 20,
                      color: '#CD363A'
                    },
                    {
                      from: 20,
                      to: 100,
                      color: '#52B12C'
                    }
                  ],
                  //inverse : true
                }
              }
            }
          },

lineChartSimple: {
    series: [
      {
        data: ['1', '2', '2', '4', '2', '2', '2', '3', '3', '2', '2', '2', '1', '1', '1'],
      },
    ],
    series2: [
      {
        data: ['28', '35', '38', '73', '45', '51', '48', '55', '57', '51', '42', '38', '41', '25', '27'],
      },
    ],    
    chartOptions: {
      chart: {
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      markers: {
        strokeWidth: 7,
        strokeOpacity: 1,
        strokeColors: [$themeColors.light],
        colors: [$themeColors.success],
      },
      colors: [$themeColors.success],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'straight',
      },
      grid: {
        xaxis: {
          lines: {
            show: true,
          },
        },
      },

      xaxis: {
        categories: [
          '2008',
          '2009',
          '2010',
          '2011',
          '2012',
          '2013',
          '2014',
          '2015',
          '2016',
          '2017',
          '2018',
          '2019',
          '2020',
          '2021',
          '2022',
        ],
      },
      yaxis: {
    labels: {
      formatter: function(val) {
        if (val == 3) 
        return "2E"
        else if (val == 4)
        return "3"
        else if (val==5)
        return "3U"
        return val.toFixed(0)
      }
    },        
    min: 0,
    max: 5,
    tickAmount: 5,      
        categories: [
          '1',
          '2',
          '2E',
          '3',
          '3U',
        ],
      },
    },
    chartOptions2: {
      chart: {
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      markers: {
        strokeWidth: 7,
        strokeOpacity: 1,
        strokeColors: [$themeColors.light],
        colors: [$themeColors.primary],
      },
      colors: [$themeColors.primary],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'straight',
      },
      grid: {
        xaxis: {
          lines: {
            show: true,
          },
        },
      },

      xaxis: {
        categories: [
          '2008',
          '2009',
          '2010',
          '2011',
          '2012',
          '2013',
          '2014',
          '2015',
          '2016',
          '2017',
          '2018',
          '2019',
          '2020',
          '2021',
          '2022',
        ],
      },
    },

  },


treemapData: [{ 
    name:"Equipements sur ouvrage", 
    data :[
      {"x": "Chaussée", "y": 45, "vétusté": 1},
      {"x": "Trottoirs et bordures", "y": 15, "vétusté": 2},
      {"x": "Dispositifs de retenue", "y": 12, "vétusté": 2},
      {"x": "Corniches", "y": 13, "vétusté": 3},
      {"x": "Dispositifs d'évacuation des eaux", "y": 18, "vétusté": 2},
      {"x": "Joints de chaussée et de trottoirs", "y": 13, "vétusté": 5},
      {"x": "Autres équipements", "y": 22, "vétusté": 1},]
      },
    {
    name:"Equipements sous ouvrage",
    data: [  
      {"x": "Chaussée de la voie franchie", "y": 35, "vétusté": 1},
      {"x": "Trottoirs et bordures", "y": 6, "vétusté": 3},
      {"x": "Dispositifs de retenue", "y": 18, "vétusté": 2},
      {"x": "Dispositifs d'évacuation des eaux", "y": 21, "vétusté": 5},]
      },
    {
      name:"Elements de protection",
      data:[
      {"x": "Etanchéité du tablier", "y": 27, "vétusté": 2},]
    },
    {
      name:"Tablier",
      data:[
      {"x": "Dalle", "y": 45, "vétusté": 2},
      {"x": "Défauts d'aspect de la dalle", "y": 38, "vétusté": 3},]
    }
    ],

      swiperOptions: {
        spaceBetween: 30,
        effect: 'fade',
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        pagination: {
          el: '.swiper-pagination',
        },
      },
      data: [
          {
            "text": "GU148",
            "selected" : true,
            "opened" : true,
             "children": [
              {
                  "text": "Rapports inspection",
            "opened" : true,
                  "children": [
                    {text:"ID 25/11/2021 (pdf)", path:"/logicoa.pdf", type:"pdf", icon:"none", created:"20/10/2008",version:"2.1",author:"John Doe"},
                    {text:"VE 12/02/2020 (doc)", path:"https://view.officeapps.live.com/op/embed.aspx?src=https://assetmgmt.dev.platform.topoeverywhere.com/GuideRedaction.doc", type:"doc", icon:"none", created:"12/03/2021",version:"0.1",author:"Jean Dupont"},
                    {text:"ID 25/11/2018 (pdf)", path:"/securite.pdf", type:"pdf", icon:"none", created:"15/12/2005",version:"2.0",author:"Jean Dupont"},                    
                  ]                
              },
              {
                "text": "Plans / Coupes",
            "opened" : true,
                  "children": [
                    {text:"plan 1", path:require('@/assets/images/pages/patrimoine/vue1.jpeg'), type:"jpeg",icon:"none", created:"20/10/2008",version:"2.1",author:"John Doe"},
                    {text:"plan 2", path:require('@/assets/images/pages/patrimoine/vue2.jpeg'), type:"jpeg", icon:"none", created:"12/03/2021",version:"0.1",author:"Jean Dupont"},
                    {text:"Plan 3", path:require('@/assets/images/pages/patrimoine/vue3.jpeg'), type:"jpeg", icon:"none", created:"15/12/2005",version:"2.0",author:"Jean Dupont"},                    
                  ]  
              },              
              {
                "text": "Photos",
            "opened" : true,
                  "children": [
                    {text:"photo 1", path:require('@/assets/images/pages/patrimoine/photo1.jpeg'), type:"jpeg", icon:"none", created:"15/12/2005",version:"2.0",author:"Jean Dupont"},
                    {text:"photo 2", path:require('@/assets/images/pages/patrimoine/photo2.jpeg'), type:"jpeg", icon:"none", created:"12/03/2021",version:"0.1",author:"Jean Dupont"},
                    {text:"photo 3", path:require('@/assets/images/pages/patrimoine/photo3.jpeg'), type:"jpeg", icon:"none", created:"20/10/2008",version:"2.1",author:"John Doe"}, 
                    {text:"photo 4", path:require('@/assets/images/pages/patrimoine/photo4.jpeg'), type:"jpeg", icon:"none", created:"12/03/2021",version:"0.1",author:"Jean Dupont"},
                    {text:"photo 5", path:require('@/assets/images/pages/patrimoine/photo5.gif'), type:"jpeg", icon:"none", created:"20/10/2008",version:"2.1",author:"John Doe"},
                    {text:"photo 6", path:require('@/assets/images/pages/patrimoine/photo6.jpeg'), type:"jpeg", icon:"none", created:"15/12/2005",version:"2.0",author:"Jean Dupont"},  
                    {text:"photo 7", path:require('@/assets/images/pages/patrimoine/photo8.jpg'), type:"jpeg", icon:"none", created:"20/10/2008",version:"2.1",author:"John Doe"},
                    {text:"photo 8", path:require('@/assets/images/pages/patrimoine/photo10.jpeg'), type:"jpeg", icon:"none", created:"12/03/2021",version:"0.1",author:"Jean Dupont"},   
                    {text:"fichier cad", path:require('@/assets/images/pages/patrimoine/photo10.jpeg'), type:"cad", icon:"none", created:"12/03/2021",version:"0.1",author:"Jean Dupont"},                                                       
                  ]  
              },    

              {
                "text": "Relevés",
                "selected": true
              },                        
              {
                "text": "Devis",
                "selected": true
              },                               
            ]
          },    
      ],           
    }
  }, 

  setup() {

    const isAddNewMaintenanceSidebarActive = ref(false)
    const isAddNewTypeinsSidebarActive = ref(false)
    const isAddNewCaracSidebarActive = ref(false)
    const isAddNewContrainteSidebarActive = ref(false)
    const isAddNewIntervenantSidebarActive = ref(false)
    const isAddNewComposantSidebarActive = ref(false)
    const ficheId = ref('')
    const arrayComposants = [
        {value:"VE",label:"Visite d'évaluation"},
        {value:"ID",label:"Inspection détaillée"},
        {value:"IDP",label:"Inspection détaillée périodique"},
        {value:"CA",label:"Controle annuel"},
    
    ]


    return {
      Composants,
      isAddNewMaintenanceSidebarActive,
      isAddNewTypeinsSidebarActive,
      isAddNewCaracSidebarActive,
      isAddNewContrainteSidebarActive,
      isAddNewIntervenantSidebarActive,
      isAddNewComposantSidebarActive,
      ficheId,
      arrayComposants,
      Assettypes
    }
  },

  methods: {
    addMaintenance: function (maintenance) { 
      maintenance.periodicite = maintenance.periodicite.value
      this.itemsMaintenance.push(maintenance)
    },  
    gotoCarnet:function() {
      this.$router.push({ path: '/apps/patrimoine/carnet/'+this.product.id})
    } ,  
    addTypeins: function (typeins) { 
      typeins.typeConf = typeins.typeConf.value
      typeins.periodicite = typeins.periodicite.value
      this.itemsConfiguration.push(typeins)
    },   
     addCarac: function (carac) { 
      this.itemsCarac.push(carac)
    },   
    showFiche: function (id) { 
      this.ficheId = id
      this.$root.$emit('bv::show::modal','modal-fiche')
    }, 
    showReleve2: function (id) { 
      this.$root.$emit('bv::show::modal','modal-releve')
    },      
    showReleve: function (id) { 
      this.$router.push({ path: '/apps/releve/releve'})
    },       
    addContrainte: function (contrainte) { 
      this.itemsContrainte.push(contrainte)
    }, 

    addIntervenant: function (intervenant) { 
      this.itemsRole.push(intervenant)
    },     

    itemClick: function (node) {
      this.document = node.model
    },

    addComposant: function (composant) { 
      this.Composants.itemsComposant.push(composant)
    },         

   onMaintenanceEdit: function (maintenance) { 
       this.inMaintenanceData = maintenance     
       this.isAddNewMaintenanceSidebarActive = true
    },       
 
     onMaintenanceNew: function () { 
       this.inMaintenanceData.id = ''  
       this.isAddNewMaintenanceSidebarActive = true
    }, 
    
   onTypeinsEdit: function (typeins) { 
       this.inTypeinsData = typeins     
       this.isAddNewTypeinsSidebarActive = true
    },       
 
     onTypeinsNew: function () { 
       this.inTypeinsData.id = ''  
       this.isAddNewTypeinsSidebarActive = true
    },  

   onCaracEdit: function (carac) { 
       this.inCaracData = carac     
       this.isAddNewCaracSidebarActive = true
    },       
 
     onCaracNew: function () { 
       this.inCaracData.id = ''  
       this.isAddNewCaracSidebarActive = true
    },  
    
   onContrainteEdit: function (contrainte) { 
       this.inContrainteData = contrainte     
       this.isAddNewContrainteSidebarActive = true
    },       
 
     onContrainteNew: function () { 
       this.inContrainteData.id = ''  
       this.isAddNewContrainteSidebarActive = true
    },  
    
  onIntervenantEdit: function (intervenant) { 
       this.inIntervenantData = intervenant     
       this.isAddNewIntervenantSidebarActive = true
    },       
 
     onIntervenantNew: function () { 
       this.inIntervenantData.id = ''  
       this.isAddNewIntervenantSidebarActive = true
    },     
   
   onComposantEdit: function (composant) { 
       this.inComposantData = composant     
       this.isAddNewComposantSidebarActive = true
    },       
 
     onComposantNew: function () { 
       this.inComposantData.id = ''  
       this.isAddNewComposantSidebarActive = true
    },     

    onFetchData: function () { 
    },   
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-patrimoine-details.scss";
div.minimalistBlack {
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}
.divTable.minimalistBlack .divTableCell, .divTable.minimalistBlack .divTableHead {
  padding: 5px 4px;
}
.divTable.minimalistBlack .divTableBody .divTableCell {
  font-size: 13px;
}
.divTable.minimalistBlack .divTableRow:nth-child(even) {
  background: #D0E4F5;
}
.divTable.minimalistBlack .divTableHeading {
  background: #7367F0;
  background: -moz-linear-gradient(top, #968df4 0%, #8176f1 66%, #7367F0 100%);
  background: -webkit-linear-gradient(top, #968df4 0%, #8176f1 66%, #7367F0 100%);
  background: linear-gradient(to bottom, #968df4 0%, #8176f1 66%, #7367F0 100%);
}
.divTable.minimalistBlack .divTableHeading .divTableHead {
  font-size: 15px;
  font-weight: bold;
  color: #FFFFFF;
  text-align: left;
}
.minimalistBlack .tableFootStyle {
  font-size: 14px;
  font-weight: bold;
  color: #000000;
  border-top: 3px solid #000000;
}
.minimalistBlack .tableFootStyle {
  font-size: 14px;
}
/* DivTable.com */
.divTable{ display: table; }
.divTableRow { display: table-row; }
.divTableHeading { display: table-header-group;}
.divTableCell, .divTableHead { display: table-cell;}
.divTableHeading { display: table-header-group;}
.divTableFoot { display: table-footer-group;}
.divTableBody { display: table-row-group;}

.swiper-navigations {
  max-width:900px;
}
.swiper-slide .img {
  max-width:900px;
}
.containeriqoa {
    display: inline-block;
    position: relative;
}

.containeriqoa .text {

    z-index: 1;
    position: absolute;
    text-align: center;
    font-family: Georgia;
    font-size: large;
    font-weight: bold;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 10%;
    color: rgb(97, 67, 207);
    width: 70%;
}
.nav-pills .nav-link {
    max-width: 400px;
    justify-content: left;
}

.caractitle {




  background:#7367f0;
  background: -moz-linear-gradient(top, #7367f0 0%, #7367f0 66%, #7367f0 100%);
  background: -webkit-linear-gradient(top, #7367f0 0%, #7367f0 66%, #7367f0 100%);
  background: linear-gradient(to bottom, #7367f0 0%, #7367f0 66%, #7367f0 100%);
  border-bottom: 2px solid #444444;
  font-size: 15px;
  font-weight: bold;
  color: #FFFFFF;
  border-left: 2px solid #D0E4F5;


}
</style>
