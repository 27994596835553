<template>
    <div ref="fichemodal">            
        <b-modal
        id="modal-fiche"
        modal-class="fiche-modal nopad"
        cancel-variant="outline-secondary"
        ok-only 
        ok-variant="secondary" 
        ok-title="Fermer"
        centered
        size="lg"
        style="width:800px;"
        title="Fiche IQOA inspection composant"
        @change="loadFiche"
        >
        <h6>Composant : {{ inFiche.nom }}</h6>

    <b-container v-for="rub in inFiche.rubriques" :key="rub.id">
        <h6 v-if="rub.title" class="mt-2">{{ rub.title }}</h6> 
        <b-row v-for="image in rub.images" :key="image">
            <b-col cols="12" class="mb-2 align-items-center" style="text-align:center;">
                <img :src="image" width="500px" margin="auto"/>
            </b-col>
        </b-row>    

        <b-row v-for="sousrub in rub.sousrubrique" :key="sousrub.id" cols="12">
            <b-row cols="12" class="text-primary" style="width:100%">
                <b-col md="5" class="m-1 pt-2 font-weight-bold" >{{ sousrub.title }}</b-col>
                <b-col md="5" class="m-1 pt-2 font-weight-bold" >{{ sousrub.comment }}</b-col>
            </b-row>


            <b-row cols="12" class="card-header ml-2 mr-2" style="width:100%">
                    <b-col md="5">Désignation des défauts </b-col>
                    <b-col md="2">Classe IQOA</b-col>
                    <b-col md="5">Observations / commentaires / croquis</b-col>            
            </b-row>            
            <b-row v-for="ques in sousrub.questions" :key="ques" cols="12" class="ml-2 mr-2" style="width:100%">
                <b-col md="5" class="border pt-2">{{ ques.libelle }}</b-col>
                <b-col md="2" class="border pt-2" style="text-align:center;">{{ ques.iqoa }}</b-col>
                <b-col md="5" class="border">  
                    <b-form-textarea
                        v-show="editable==='1'"
                        id="textarea-rows"
                        :placeholder="ques.comment"
                        style="border:none;"
                        rows="8"
                    /></b-col>
            </b-row>




        </b-row>           
    </b-container>

        </b-modal>
    </div>
</template>

<script>

import {
BButton, BModal, VBModal, BForm, BFormInput, BFormGroup, BCardText,BTable,BLink, BContainer, BRow, BCol, BFormTextarea
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import chartedit from './ChartEdit.vue'
import Fiche from '@/@fake-db/data/apps/fichecomposant.js'

export default {
  directives: {
    Ripple,
  },  
  model: {
    prop: 'idtoshow',
  },    
  props: {
    idtoshow: {
      type: String,
      required: false,
    },
    editable: {
      type: String,
      required: false,
      default: ""
    },
  },  
  name: "fichemodal",
  components: {BButton, BModal, VBModal, BForm, BFormInput, BFormGroup, BCardText,chartedit, Fiche,BTable,BLink,BContainer, BRow, BCol,BFormTextarea},
  methods: {       
    hideModal (id) {
        this.$root.$emit('bv::hide::modal',id)
    },
    loadFiche() {
      if (this.idtoshow != '') {
        const inspectionIndex = this.Fiche.composants.findIndex(e => e.id === this.idtoshow)
        this.inFiche = this.Fiche.composants[inspectionIndex]
      }
    },    
  },
  
   data() { 
       return {
           fieldsTypeouvrage: [
                { key: "famille", label: "Famille d'ouvrage", type: "text",thStyle: { width: "20%", height: "40px" }  },
                { key: "type", label: "Type d'ouvrage", type: "text",thStyle: {height: "40px" } },
            ], 
            inFiche:{}, 
       }
   },
  setup(props) { 
    return {
      Fiche,
    }
  },   
  };
</script>

<style>
.ouvrage-modal .modal-dialog{
    max-width: 800px;
}
.nopad th, .nopad td {
    padding: 0px;
}

.ouvrage-modal .modal-body          { overflow: auto; height: 600px;padding:0; }
.ouvrage-modal .modal-body  thead th { position: sticky; top: 0; z-index: 1; }

/* Just common table stuff. Really. */
.ouvrage-modal table  { border-collapse: collapse; width: 100%; }
.ouvrage-modal th     { background:#eee; }
.ouvrage-modal .custom-checkbox{
    margin-left: 15px;
}
</style>


