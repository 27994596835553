<template>
  <b-sidebar
    id="add-new-composant-sidebar"
    :visible="isAddNewComposantSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => {$emit('update:is-add-new-composant-sidebar-active', val);showComposant(val)}"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Ajout d'un nouveau composant
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="type"
            rules="required"
          >
            <b-form-group
              label="Type de composant"
              label-for="type"
            >
              <b-form-input
                id="type"
                v-model="composantData.type"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />        
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="nature"
          >
            <b-form-group
              label="Nature du composant"
              label-for="nature"
            >
              <b-form-input
                id="nature"
                v-model="composantData.nature"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />        
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button v-if="!composantData.id"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Ajouter
            </b-button>
            <b-button v-if="composantData.id"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Mettre à jour
            </b-button>            
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Annuler
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'


export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewComposantSidebarActive',
    prop: 'composantsList',
    event: 'update:is-add-new-composant-sidebar-active',
  },
  props: {
    isAddNewComposantSidebarActive: {
      type: Boolean,
      required: true,
    },
    inComposantData: {
      type: Object,
      default: (() => {id:''}),
    },   
    composantsList: {
      type: Array,
      default: []
    }, 
  },

 methods: {
    loadComposant() {
      if (this.inComposantData.id != '') {
        this.composantData = JSON.parse(JSON.stringify(this.inComposantData))
      } else {
        this.composantData = JSON.parse(JSON.stringify(this.blankComposantData))
      }
    },
    showComposant: function (val) { 

      if (val) {
        this.loadComposant()
      } else {
        this.composantData = JSON.parse(JSON.stringify(this.blankComposantData))
      }
    },
 },  
 
  data() {

    this.loadComposant()
 
    return {
      required,
      alphaNum,
      email,
      types:['Fissure','Altération','type 1','type 2'],
      iqoas:['1','2','2E','3','3U'],
    }
  },

created () {
    this.blankComposantData = {
      id: '',
      type:'',
      nature: '',
      iqoa:"1",
      fiche: '',
    }


},

  setup(props, { emit }) {
    const blankComposantData = {
      id: '',
      type:'',
      nature: '',
      iqoa:"1",
      fiche: '',
    }

    let composantData = ref(JSON.parse(JSON.stringify(blankComposantData)))

    if (props.inComposantData.id != '') {
      composantData.value = JSON.parse(JSON.stringify(blankComposantData))
    }

    const resetcomposantData = () => {
      composantData.value = JSON.parse(JSON.stringify(blankComposantData))
    }

    const onSubmit = () => {
      emit('update:is-add-new-composant-sidebar-active', false)
      emit('addcomposant', composantData.value)
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetcomposantData)

    return {
      composantData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-composant-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
