<template>
  <b-sidebar
    id="add-new-carac-sidebar"
    :visible="isAddNewCaracSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => {$emit('update:is-add-new-carac-sidebar-active', val);showCarac(val)}"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Ajout d'une nouvelle caractéristique
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="carac"
            rules="required"
          >
            <b-form-group
              label="Caractéristique"
              label-for="carac"
            >
              <b-form-input
                id="carac"
                v-model="caracData.carac"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />        
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Username -->
          <validation-provider
            #default="validationContext"
            name="valeur"
            rules="required"
          >
            <b-form-group
              label="Valeur"
              label-for="valeur"
            >
              <b-form-input
                id="valeur"
                v-model="caracData.valeur"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>


          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button v-if="!caracData.id"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Ajouter
            </b-button>
            <b-button v-if="caracData.id"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Mettre à jour
            </b-button>            
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Annuler
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'


export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewCaracSidebarActive',
    prop: 'composantsList',
    event: 'update:is-add-new-carac-sidebar-active',
  },
  props: {
    isAddNewCaracSidebarActive: {
      type: Boolean,
      required: true,
    },
    inCaracData: {
      type: Object,
      default: (() => {id:''}),
    },   
    composantsList: {
      type: Array,
      default: []
    }, 
  },

 methods: {
    loadCarac() {
      if (this.inCaracData.id != '') {
        this.caracData = JSON.parse(JSON.stringify(this.inCaracData))
      } else {
        this.caracData = JSON.parse(JSON.stringify(this.blankCaracData))
      }
    },
    showCarac: function (val) { 

      if (val) {
        this.loadCarac()
      } else {
        this.caracData = JSON.parse(JSON.stringify(this.blankCaracData))
      }
    },
 },  
 
  data() {

    this.loadCarac()
 
    return {
      required,
      alphaNum,
      email,
      types:['Fissure','Altération','type 1','type 2'],
      iqoas:['1','2','2E','3','3U'],
    }
  },

created () {
    this.blankCaracData = {
      id: '',
      carac: '',
      valeur: '',
    }
},

  setup(props, { emit }) {
    const blankCaracData = {
      id: '',
      carac: '',
      valeur: '',
    }

    let caracData = ref(JSON.parse(JSON.stringify(blankCaracData)))

    if (props.inCaracData.id != '') {
      caracData.value = JSON.parse(JSON.stringify(blankCaracData))
    }

    const resetcaracData = () => {
      caracData.value = JSON.parse(JSON.stringify(blankCaracData))
    }

    const onSubmit = () => {
      emit('update:is-add-new-carac-sidebar-active', false)
      emit('addcarac', caracData.value)
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetcaracData)

    return {
      caracData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-carac-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
