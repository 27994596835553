<template>
<div>
        <span style="float:right"><b-button
    variant="outline-primary"
    class="ml-2 mt-2 mb-2"
    >
    <span class="text-nowrap">Ajouter un nouveau fichier au dossier</span>
    </b-button></span>
      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="fileList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="No matching records found"
      >

        <!-- Column: User -->
        <template #cell(title)="data">   
            <b-link> {{ data.item.title }} </b-link>
              
        </template>

        <template #cell(author)="data">

              {{ data.item.author }}
        </template>        

        <!-- Column: Role -->
        <template #cell(created)="data">
          <span> {{ data.item.created }} </span>
        </template>

        <!-- Column: Status -->
        <template #cell(version)="data">
          <span> {{ data.item.version }} </span>
        </template>



      </b-table>
      </div>
</template>

     <script>

import {
BTable, BLink, BButton 
} from 'bootstrap-vue'


export default {
  components: {
    BTable,BLink,BButton
  },

    setup() {

        const tableColumns = [
            { key: 'title',label:"Nom fichier", sortable: true }, 
            { key: 'author',label:"Auteur", sortable: true },  
            { key: 'created',label:"Date", sortable: true },            
            { key: 'version',label:"Version", sortable: true }, 
        ]
        return { tableColumns}
    },


  data() {
    return {
        fileList: [
            {title:'Dossier de surveillance renforcée',author:'John Doe',created:'12/03/2021',version:'1.0'},
            {title:'Convention de gestion',author:'John Doe',created:'15/04/2004',version:'2.1'},
            {title:'procès-verbal de l’inspection détaillée initiale',author:'Rémi Dupont',created:'11/01/1996',version:'1.0'},
            {title:'Journal de chantier',author:'Rémi Dupont',created:'08/05/1995',version:'1.3'},
        ]
    }
  },
}
</script>