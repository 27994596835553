export default 
    {
        composants: [
            {id:"1",
                         nom:"Chaussée de la voie portée",   
                        rubriques:[
                            {
                                id:1 ,
                            images:[require('@/assets/images/ficheiqoa/img1.png'),require('@/assets/images/ficheiqoa/img2.png')],
                            title:"",
                            sousrubrique:[
                                {
                                    id:11 ,
                                    title:"1 - Déformation vers le bas",
                                    comment:"Localiser la déformation, indiquer la valeur approximative de la flèche",
                                    questions:[
                                        {libelle:"1.1 sur ouvrage",iqoa:"2", comment:""},
                                        {libelle:"1.2 hors ouvrage",iqoa:"2", comment:"Ceci peut être révélateur d'un désordre grave de la structure"},
                                    ]
                                },
                                {
                                    id:12 ,
                                    title:"2 - Effondrement local",
                                    comment:"Localiser l'effondrement, indiquer la valeur approximative de la profondeur",
                                    questions:[
                                        {libelle:"Présence du défaut",iqoa:"*", comment:"Ceci peut être révélateur d'un désordre grave de la structure"},
                                    ]
                                }                               
                            ]
                            },    
                            {
                                id:2 ,
                            images:[require('@/assets/images/ficheiqoa/img3.png'),require('@/assets/images/ficheiqoa/img4.png')],
                            title:"3 - Fissures",
                            sousrubrique:[
                                {
                                    id:21 ,
                                    title:"3.1 - De type I",
                                    comment:"",
                                    questions:[
                                        {libelle:"d'ouverture inférieure à 1 cm",iqoa:"1", comment:""},
                                        {libelle:"d'ouverture supérieure à 1 cm",iqoa:"2", comment:""},
                                    ]
                                },
                                {
                                    id:22 ,
                                    title:"3.2 - De type II",
                                    comment:"",
                                    questions:[
                                        {libelle:"d'ouverture inférieure à 1 cm",iqoa:"1", comment:""},
                                        {libelle:"d'ouverture supérieure à 1 cm",iqoa:"2", comment:""},
                                    ]
                                },
                                {
                                    id:23 ,
                                    title:"3.3 - De type III",
                                    comment:"",
                                    questions:[
                                        {libelle:"d'ouverture inférieure à 1 cm sans rejet",iqoa:"1", comment:""},
                                        {libelle:"d'ouverture supérieure à 1 cm sans rejet",iqoa:"2", comment:""},
                                        {libelle:"avec rejet",iqoa:"*", comment:""},
                                    ]
                                },    
                                {
                                    id:24 ,
                                    title:"3.4 - De type IV",
                                    comment:"",
                                    questions:[
                                        {libelle:"Présence du défaut",iqoa:"*", comment:"Ceci peut-être révélateur d'un désordre grave de la structure"},
                                    ]
                                },                                                                                             
                            ]
                            },     
                            {
                                id:3 ,
                                images:[require('@/assets/images/ficheiqoa/img5.png'),require('@/assets/images/ficheiqoa/img6.png'),require('@/assets/images/ficheiqoa/img7.png')],
                                sousrubrique:[
                                    {
                                        id:31 ,
                                        title:"4 - Faïençage",
                                        comment:"Préciser l'étendue",
                                        questions:[
                                            {libelle:"Présence du défaut",iqoa:"1 ou 2", comment:""},
                                        ]
                                    },
                                    {
                                        id:32 ,
                                        title:"5 - Nids de poule",
                                        comment:"",
                                        questions:[
                                            {libelle:"Présence du défaut",iqoa:"1 ou 2", comment:""},
                                        ]
                                    },
                                    {
                                        id:33 ,
                                        title:"6 - Défauts de surface",
                                        comment:"Localiser le (ou les) défauts",
                                        questions:[
                                            {libelle:"(flache, pelade, orniérage, bourrelet, remontée d'eau)",iqoa:"1 ou 2", comment:""},
                                        ]
                                    },    
                                    {
                                        id:34 ,
                                        title:"7 - Affaissement de la chaussée à l'arrière d'une culée",
                                        comment:"Localiser le (ou les) affaissement(s)",
                                        questions:[
                                            {libelle:"Présence du défaut",iqoa:"*", comment:"Ceci peut-être révélateur d'un désordre grave de la structure"},
                                        ]
                                    },           
                                    {
                                        id:35 ,
                                        title:"8 - Autres défauts",
                                        comment:"",
                                        questions:[
                                            {libelle:"A décrire en les illustrant par un croquis ou une photographie",iqoa:"*", comment:""},
                                        ]
                                    },                                                                                                                        
                                ]
                                },                                                                         
                        ]
                    },
                    {id:"2",
                    nom:"Trottoirs, bordures et accotements",   
                   rubriques:[
                       {
                        id:4 ,
                       images:[require('@/assets/images/ficheiqoa/img8.png')],
                       title:"",
                       sousrubrique:[
                           {
                            id:41 ,
                               title:"9 - Défauts des bordures de trottoirs",
                               comment:"Localiser le désordre et préciser s'il s'agit du trottoir de gauche ou de droite",
                               questions:[
                                   {libelle:"défaut d 'alignement général ou localisé",iqoa:"2", comment:""},
                                   {libelle:"absence d'un ou de plusieurs éléments",iqoa:"2", comment:""},
                                   {libelle:"altération (disjointement, épaufrures, effritement)",iqoa:"2", comment:""},
                               ]
                           },
                           {
                            id:42 ,
                               title:"10 - Défauts sur trottoirs",
                               comment:"Localiser l'effondrement, indiquer la valeur approximative de la profondeur",
                               questions:[
                                    {libelle:"dégradation de revêtement du trottoir",iqoa:"1 ou 2", comment:""},
                                    {libelle:"déformation de surface",iqoa:"1 ou 2", comment:""},
                                    {libelle:"présence de végétation",iqoa:"1 ou 2", comment:""},
                               ]
                           },
                           {
                            id:43 ,
                            title:"11 - Affaissement du corps de trottoir",
                            comment:"Localiser l'effondrement, indiquer la valeur approximative de la profondeur",
                            questions:[
                                 {libelle:"sur ouvrage ou hors ouvrage ",iqoa:"2", comment:""},
                            ]
                            },
                            {
                                id:44 ,
                                title:"12 - Défaut d'étanchéité du revêtement de trottoir",
                                comment:"Localiser l'effondrement, indiquer la valeur approximative de la profondeur",
                                questions:[
                                     {libelle:"Présence du défaut",iqoa:"*", comment:""},
                                ]
                            },   
                            {
                                id:45 ,
                                title:"13 - Configuration de l'ensemble chaussée accotement",
                                comment:"",
                                questions:[
                                     {libelle:"Favorisant la stagnation de l'eau ou les ruissellements vers les murs de tête (parapets tympans, murs en retour) et/ou favorisant la circulation des véhicules trop près des parapets",iqoa:"*", comment:""},
                                ]
                            },  
                            {
                                id:46 ,
                                title:"14 - Autres défauts",
                                comment:"",
                                questions:[
                                     {libelle:"A décrire en les illustrant par un croquis ou une photographie",iqoa:"*", comment:""},
                                ]
                            },                                                                                                                                                 
                       ]
                       },    
                                                                                              
                   ]
               },            
        ]
    }
  
  