<template>
  <b-sidebar
    id="add-new-desordre-sidebar"
    :visible="isAddNewDesordreSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => {$emit('update:is-add-new-desordre-sidebar-active', val);showDesordre(val)}"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0" v-if="!desordreData.id">
          Ajout d'un nouveau désordre
        </h5>

        <h5 class="mb-0" v-if="desordreData.id">
          Modification d'un désordre
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
            <span style="float:right" class="mb-1">          
            <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(desordreData.status)}`"
            class="text-capitalize"
          >
            {{ desordreData.status }}
          </b-badge></span>
          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="libelle"
            rules="required"
          >
            <b-form-group
              label="Dénomination"
              label-for="libelle"
            >
              <b-form-input
                id="libelle"
                v-model="desordreData.libelle"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />
              <input type="hidden" name="status"  v-model="desordreData.status"/>
              <input type="hidden" name="created"   v-model="desordreData.created"/> 
              <input type="hidden" name="id"   v-model="desordreData.id"/>  
              <input type="hidden" name="author"   v-model="desordreData.author"/> 
              <input type="hidden" name="inspection"   v-model="desordreData.inspection"/>            
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>

            </b-form-group>
          </validation-provider>

          <!-- Username -->
          <validation-provider
            #default="validationContext"
            name="description"
            rules="required"
          >
            <b-form-group
              label="Description"
              label-for="description"
            >
              <b-form-input
                id="description"
                v-model="desordreData.description"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Email -->
          <validation-provider
            #default="validationContext"
            name="iqoa"
            rules="required"
          >
            <b-form-group
              label="Indice IQOA"
              label-for="iqoa"
            >
              <v-select
                v-model="desordreData.iqoa"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options=iqoas
                :clearable="false"
                input-id="desordre-type"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Company -->
          <validation-provider
            #default="validationContext"
            name="ouvrage"
            rules="required"
          >
            <b-form-group
              label="Ouvrage / Composant"
              label-for="ouvrage"
            >

              <v-select
                v-model="desordreData.composants"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="composantsList"
                :clearable="false"
                input-id="ouvrage"
              />              

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- User Role -->
          <validation-provider
            #default="validationContext"
            name="type"
            rules="required"
          >
            <b-form-group
              label="Type"
              label-for="type"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="desordreData.type"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options=types
                :clearable="false"
                input-id="desordre-type"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Plan -->
          <validation-provider
            #default="validationContext"
            name="suiteadonner"
            rules="required"
          >
            <b-form-group
              label="Suite à donner"
              label-for="suiteadonner"
              :state="getValidationState(validationContext)"
            >
              <b-form-input
                id="suiteadonner"
                v-model="desordreData.suiteadonner"
                :state="getValidationState(validationContext)"
                trim
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

            <b-form-group
              label="Historique"
              label-for="historique"
            >
             <span v-for='value of desordreData.historique'>{{ value }}<br/></span>
            </b-form-group>             

            <span v-for='value of desordreData.documents' v-if="desordreData.id">
              <b-img :src=value width="300px" class="m-1"></b-img>
            </span>   


          <validation-provider
            #default="validationContext"
            name="documents"
            v-if="!desordreData.id"
          >
            <b-form-group
              label="Photos"
              label-for="documents"
            >
              <b-form-file
              placeholder="Prendre une photo"
              multiple
              v-model="desordreData.documents"
              />      
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>


          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button v-if="!desordreData.id"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Ajouter
            </b-button>
            <b-button v-if="desordreData.id"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Mettre à jour
            </b-button>            
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Annuler
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,BImg, BFormFile, BBadge
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import useDesordresList from '@/views/apps/desordre/useDesordresList'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BImg,
    BFormFile,
    BBadge,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewDesordreSidebarActive',
    prop: 'composantsList',
    event: 'update:is-add-new-desordre-sidebar-active',
  },
  props: {
    isAddNewDesordreSidebarActive: {
      type: Boolean,
      required: true,
    },
    inDesordreData: {
      type: Object,
      default: (() => {id:''}),
    },   
    composantsList: {
      type: Array,
      default: []
    }, 
  },

 methods: {
    loadDesordre() {
      if (this.inDesordreData.id != '') {
        this.desordreData = this.inDesordreData
      } else {
        this.desordreData = JSON.parse(JSON.stringify(this.blankDesordreData))
      }
    },
    showDesordre: function (val) { 

      if (val) {
        this.loadDesordre()
      } else {
        this.desordreData = JSON.parse(JSON.stringify(this.blankDesordreData))
      }
    },


 },  
 
  data() {
    //this.desordreData.value = this.inDesordreData
    this.loadDesordre()
 
    return {
      required,
      alphaNum,
      email,
      types:['Fissure','Altération','type 1','type 2'],
      iqoas:['1','2','2E','3','3U'],
    }
  },

created () {
    this.blankDesordreData = {
      id: '',
      libelle: '',
      description: '',
      iqoa: '',
      composants: null,
      created: new Date().toLocaleDateString("fr-FR"),
      status: 'Crée',
      author: 'John Doe',
      type: '',
    }
},

  setup(props, { emit }) {
    const blankDesordreData = {
      id: '',
      libelle: '',
      description: '',
      iqoa: '',
      composants: null,
      created: new Date().toLocaleDateString("fr-FR"),
      status: 'Crée',
      author: 'John Doe',
      type: '',
    }

    let desordreData = ref(JSON.parse(JSON.stringify(blankDesordreData)))

    if (props.inDesordreData.id != '') {
      desordreData.value = JSON.parse(JSON.stringify(blankDesordreData))
    }

    const resetdesordreData = () => {
      desordreData.value = JSON.parse(JSON.stringify(blankDesordreData))
    }

    const onSubmit = () => {

      emit('update:is-add-new-desordre-sidebar-active', false)
      emit('adddesordre', desordreData.value)
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetdesordreData)

    const {

      resolveUserStatusVariant,

    } = useDesordresList()

    return {
      desordreData,
      onSubmit,
      blankDesordreData,
      refFormObserver,
      getValidationState,
      resetForm,
      resolveUserStatusVariant,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-desordre-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
