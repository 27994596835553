<template>
  <b-sidebar
    id="add-new-maintenance-sidebar"
    :visible="isAddNewMaintenanceSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => {$emit('update:is-add-new-maintenance-sidebar-active', val);showMaintenance(val)}"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Ajout d'une nouvelle maintenance préventive
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="typeConf"
            rules="required"
          >
            <b-form-group
              label="Type de maintenance"
              label-for="typeConf"
            >
              <b-form-input
                id="typeConf"
                v-model="maintenanceData.typeConf"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />
              <input type="hidden" name="next"  v-model="maintenanceData.next"/>
              <input type="hidden" name="prev"   v-model="maintenanceData.prev"/>           
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Username -->
          <validation-provider
            #default="validationContext"
            name="periodicite"
            rules="required"
          >
            <b-form-group
              label="Périodicité"
              label-for="periodicite"
            >
              <v-select
                v-model="maintenanceData.periodicite"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options=periodicite
                :clearable="false"
                input-id="periodicite"
              />   

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Email -->
          <validation-provider
            #default="validationContext"
            name="budget"
            rules="required"
          >
            <b-form-group
              label="Budget"
              label-for="budget"
            >
              <b-form-input
                id="budget"
                v-model="maintenanceData.budget"
                :state="getValidationState(validationContext)"
                trim
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Company -->
          <validation-provider
            #default="validationContext"
            name="duree"
            rules="required"
          >
            <b-form-group
              label="Durée"
              label-for="duree"
            >

              <b-form-input
                id="duree"
                v-model="maintenanceData.duree"
                :state="getValidationState(validationContext)"
                trim
              />           

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button v-if="!maintenanceData.id"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Ajouter
            </b-button>
            <b-button v-if="maintenanceData.id"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Mettre à jour
            </b-button>            
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Annuler
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewMaintenanceSidebarActive',
    prop: 'composantsList',
    event: 'update:is-add-new-maintenance-sidebar-active',
  },
  props: {
    isAddNewMaintenanceSidebarActive: {
      type: Boolean,
      required: true,
    },
    inMaintenanceData: {
      type: Object,
      default: (() => {id:''}),
    },   
    composantsList: {
      type: Array,
      default: []
    }, 
  },

 methods: {
    loadMaintenance() {
      if (this.inMaintenanceData.id != '') {
        this.maintenanceData = JSON.parse(JSON.stringify(this.inMaintenanceData))
      } else {
        this.maintenanceData = JSON.parse(JSON.stringify(this.blankMaintenanceData))
      }

    },
    showMaintenance: function (val) { 

      if (val) {
        this.loadMaintenance()
      } else {
        this.maintenanceData = JSON.parse(JSON.stringify(this.blankMaintenanceData))
      }
    },
 },  
 
  data() {

    this.loadMaintenance()
 
    return {
      required,
      alphaNum,
      email,
      types:['Fissure','Altération','type 1','type 2'],
      iqoas:['1','2','2E','3','3U'],
      periodicite:[
        {value:'1 an', label:"1 an"},
        {value:'2 ans', label:"2 ans"},
        {value:'3 ans', label:"3 ans"},
        {value:'4 ans', label:"4 ans"},
        {value:'5 ans', label:"5 ans"},
        {value:'6 ans', label:"6 ans"},
        {value:'7 ans', label:"7 ans"},
        {value:'8 ans', label:"8 ans"},
        {value:'9 ans', label:"9 ans"},
        {value:'10 ans', label:"10 ans"},
      ]      
    }
  },

created () {
    this.blankMaintenanceData = {
      id: '',
      periodicite: '',
      typeConf: '',
      next: '',
      prev: '',
      budget: '',
      duree: '',
    }
},

  setup(props, { emit }) {
    const blankMaintenanceData = {
      id: '',
      periodicite: '',
      typeConf: '',
      next: '',
      prev: '',
      budget: '',
      duree: '',
    }

    let maintenanceData = ref(JSON.parse(JSON.stringify(blankMaintenanceData)))

    if (props.inMaintenanceData.id != '') {
      maintenanceData.value = JSON.parse(JSON.stringify(blankMaintenanceData))
    }

    const resetmaintenanceData = () => {
      maintenanceData.value = JSON.parse(JSON.stringify(blankMaintenanceData))
    }

    const onSubmit = () => {
      emit('update:is-add-new-maintenance-sidebar-active', false)
      emit('addmaintenance', maintenanceData.value)
     /* store.dispatch('app-desordre/addDesordre', desordreData.value)
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-desordre-sidebar-active', false)
        })*/
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetmaintenanceData)

    return {
      maintenanceData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-maintenance-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
