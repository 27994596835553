<template>
  <swiper
    class="swiper"
    :options="swiperOption"
    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
  >
    <swiper-slide
      v-for="(data,index) in swiperData"
      :key="index"
    >
      <b-img
        :src="data.img"
        fluid
      />
    </swiper-slide>

    <div
      slot="pagination"
      class="swiper-pagination"
    />
  </swiper>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import { BImg } from 'bootstrap-vue'
import 'swiper/css/swiper.css'

export default {
  components: {
    Swiper,
    SwiperSlide,
    BImg,
  },
  data() {
    return {
      swiperData: [
        /* eslint-disable global-require */
        { img: require('@/assets/images/pages/patrimoine/photo1.jpeg') },
        { img: require('@/assets/images/pages/patrimoine/photo2.jpeg') },
        { img: require('@/assets/images/pages/patrimoine/photo3.jpeg') },
        { img: require('@/assets/images/pages/patrimoine/photo4.jpeg') },
        { img: require('@/assets/images/pages/patrimoine/photo5.gif') },
        { img: require('@/assets/images/pages/patrimoine/photo6.jpeg') },
        { img: require('@/assets/images/pages/patrimoine/photo7.jpeg') },
        { img: require('@/assets/images/pages/patrimoine/photo8.jpg') },
        { img: require('@/assets/images/pages/patrimoine/photo10.jpeg') },
        { img: require('@/assets/images/pages/patrimoine/photo11.jpeg') },
        { img: require('@/assets/images/pages/patrimoine/photo12.jpeg') },        
        /* eslint-disable global-require */
      ],
      swiperOption: {
        slidesPerView: 3,
        slidesPerColumn: 2,
        spaceBetween: 30,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        breakpoints: {
          1024: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
          640: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
        },
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.swiper {

  ::v-deep .swiper-wrapper {
    flex-direction: row !important;
  }
  .swiper-slide {
    margin-top: 30px;
  }
}

.swiper img {
    width: 100%;
    height: 100%; 
    object-fit: contain;
}

</style>
 